import axios from 'axios';
import crypto_js from 'crypto-js';

export const API_LIST = {
  SIGN_UP_REQUEST: 0,
  SIGN_UP_INFO: 1,
  SIGN_IN: 2,
  USER_INFO_GET: 3,
  USER_INFO_UPDATE: 4,
  USER_ROLE_SET: 5,
  USER_ROLE_GET: 6,
  USER_SEARCH: 7,
  PROJECT_GET: 10,
  BUILDING_GET: 11,
  FIELD_GET: 12,
  ZONE_GET: 13,
  PROJECT_BATCH_GET: 14,
  PROVISION_NODE_CREATE: 15,
  PROVISION_NODE_DELETE: 16,
  PROVISION_NODE_GET: 17,
  PROVISION_NODE_UPDATE: 18,
  ZONE_BATCH_GET: 19,
  USER_ROLE_DELETE: 20,
  PW_INIT_REQUEST: 23,
  NODE_STATUS_GET: 40,
  NODE_LIGHT_LEVEL_SET: 41,
  GROUP_LIGHT_LEVEL_SET: 42,
  GROUP_LC_SET: 44,
  ALLOCATED_GATEWAY_CHECK: 45,
  VIEW_HISTORY_SET: 50,
  NODE_INFO_GET: 60,

  PROJECT_CREATE: 3000,
  PROJECT_UPDATE: 3001,
  PROJECT_DELETE: 3002,
  BUILDING_CREATE: 3003,
  BUILDING_UPDATE: 3004,
  BUILDING_DELETE: 3005,
  FIELD_CREATE: 3006,
  FIELD_UPDATE: 3007,
  FIELD_DELETE: 3008,
  ZONE_CREATE: 3009,
  ZONE_UPDATE: 3010,
  ZONE_DELETE: 3011,
  PROFILE_APPLIED_GET: 3012,
  PROFILE_CREATE: 3013,
  PROFILE_GET: 3014,
  PROFILE_UPDATE: 3015,
  PROFILE_LIST_GET: 3016,
  POWER_STATISTICS_GET: 3017,
  POWER_PAYMENT_GET: 3020,
};

export const API_RESPONSE_CODE = {
  OK: 200,
  CREATED: 201,
  ACCEPTED: 202,
  NO_CONTENT: 204,
  MOVED_PERMANENTLY: 301,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  METHOD_NOT_ALLOWED: 405,
  NOT_ACCEPTABLE: 406,
  REQUEST_TIMEOUT: 408,
  PRECONDITION_FAILED: 412,
  SERVICE_UNAVAILABLE: 503
};

const SERVER_DOMAIN = {
  TESTBED: 'https://neostack-tb.com',
  COMMERCIAL: 'https://emblaze-iot.com'
};

const SERVER_KEY = {
  TESTBED: {
    SECRET_KEY: 'R0rV9OLUNKTibVH3yrEgex9Qtp60eocLkJpl9xGV',
    ACCESS_KEY: 'cH46CjoomDTqGQj42mtn'
  },
  COMMERCIAL: {
    SECRET_KEY: 'uF7iUjSe1AMFTjXohE0sTN94FGMWrV9kJMye0dVs',
    ACCESS_KEY: 'B31znags11WK0bOamXsU'
  }
};

const SERVER_TYPE =
  // 'TESTBED';
  'COMMERCIAL';

const USE_SERVER_DOMAIN = (SERVER_TYPE === 'COMMERCIAL') ? SERVER_DOMAIN.COMMERCIAL : SERVER_DOMAIN.TESTBED;
const USE_SERVER_SECRET_KEY = (SERVER_TYPE === 'COMMERCIAL') ? SERVER_KEY.COMMERCIAL.SECRET_KEY : SERVER_KEY.TESTBED.SECRET_KEY;
const USE_SERVER_ACCESS_KEY = (SERVER_TYPE === 'COMMERCIAL') ? SERVER_KEY.COMMERCIAL.ACCESS_KEY : SERVER_KEY.TESTBED.ACCESS_KEY;

const MAIN_SERVICE_INFO = {
  HOST: USE_SERVER_DOMAIN,
  PORT: 30000,
  URL: {
    COMMON: 'common',
    PLANNER: 'plan'
  }
};

const PAYMENT_CAL_SERVICE_INFO = {
  HOST: USE_SERVER_DOMAIN,
  PORT: 30006,
  URL: {
    PLANNER: 'plan'
  }
};

const IMAGE_SERVICE_INFO = {
  HOST: USE_SERVER_DOMAIN,
  PORT: 30001
};

export const METHOD = {
  GET: 'GET',
  POST: 'POST'
};

const CRYPTO = {
  SECRET_KEY: USE_SERVER_SECRET_KEY,
  ACCESS_KEY: USE_SERVER_ACCESS_KEY,
  SEGMENT: 'EMBLAZE NEOSTACK'
};

const HEADER_KEY = {
  CONTENT_TYPE: 'content-type',
  TIMESTAMP: 'emblaze-ts-v1',
  SIGNATURE: 'emblaze-sg-v1',
  ACCESS_TOKEN: 'emblaze-at-v1',
  REFRESH_TOKEN: 'emblaze-rt-v1'
};

function emblazeCrypto(method, timestamp) {
  return crypto_js.enc.Base64.stringify(crypto_js.HmacSHA256(`${method}${CRYPTO.SEGMENT}\n${timestamp}\n${CRYPTO.ACCESS_KEY}`, CRYPTO.SECRET_KEY));
}

export async function ApiRefresh(data, access_token, refresh_token) {
  const timestamp = new Date().getTime();
  let method = METHOD.POST;
  let url = MAIN_SERVICE_INFO.HOST + ':' + MAIN_SERVICE_INFO.PORT + '/' + MAIN_SERVICE_INFO.URL.COMMON;
  let api = API_LIST.USER_INFO_GET;

  return await axios({
    method: method,
    url: url,
    headers: {
      [HEADER_KEY.CONTENT_TYPE]: 'application/json',
      [HEADER_KEY.TIMESTAMP]: timestamp,
      [HEADER_KEY.SIGNATURE]: emblazeCrypto(method, timestamp),
      [HEADER_KEY.ACCESS_TOKEN]: access_token,
      [HEADER_KEY.REFRESH_TOKEN]: refresh_token
    },
    data: {
      api: api,
      ...data
    }
  });
}

export async function ApiMain(api, method, data, access_token) {
  const timestamp = new Date().getTime();
  let url = MAIN_SERVICE_INFO.HOST + ':' + MAIN_SERVICE_INFO.PORT + '/';
  if (api < 1000) {
    url = url + MAIN_SERVICE_INFO.URL.COMMON;
  } else {
    url = url + MAIN_SERVICE_INFO.URL.PLANNER;
  }

  return await axios({
    method: method,
    url: url,
    headers: {
      [HEADER_KEY.CONTENT_TYPE]: 'application/json',
      [HEADER_KEY.TIMESTAMP]: timestamp,
      [HEADER_KEY.SIGNATURE]: emblazeCrypto(method, timestamp),
      [HEADER_KEY.ACCESS_TOKEN]: access_token
    },
    data: {
      api: api,
      ...data
    }
  });
}

export async function ApiPayment(api, method, data, access_token, cancel_token) {
  const timestamp = new Date().getTime();
  let url = PAYMENT_CAL_SERVICE_INFO.HOST + ':' + PAYMENT_CAL_SERVICE_INFO.PORT + '/' + PAYMENT_CAL_SERVICE_INFO.URL.PLANNER;
  return await axios({
    method: method,
    url: url,
    headers: {
      [HEADER_KEY.CONTENT_TYPE]: 'application/json',
      [HEADER_KEY.TIMESTAMP]: timestamp,
      [HEADER_KEY.SIGNATURE]: emblazeCrypto(method, timestamp),
      [HEADER_KEY.ACCESS_TOKEN]: access_token
    },
    data: {
      api: api,
      ...data
    },
    cancelToken: cancel_token
  });
}

export async function ApiImage(img_url, method, data, access_token) {
  const timestamp = new Date().getTime();
  let url = IMAGE_SERVICE_INFO.HOST + ':' + IMAGE_SERVICE_INFO.PORT + '/' + img_url;

  return await axios({
    method: method,
    url: url,
    headers: {
      [HEADER_KEY.CONTENT_TYPE]: 'multipart/form-data',
      [HEADER_KEY.TIMESTAMP]: timestamp,
      [HEADER_KEY.SIGNATURE]: emblazeCrypto(method, timestamp),
      [HEADER_KEY.ACCESS_TOKEN]: access_token
    },
    responseType: 'blob',
    data: data
  });
}