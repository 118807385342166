import React, { useContext, useEffect, useState } from 'react';
import Draggable from 'react-draggable';
import { Modal, Space, Typography, Tag, Tooltip, Divider, App } from 'antd';
import { PlusOutlined, BulbFilled, BulbOutlined, CheckOutlined } from '@ant-design/icons';
import { DesignContext } from '../../pages/Design';
import { API_LIST, API_RESPONSE_CODE, ApiMain, METHOD } from '../../common/Api';
import { AppContext } from '../../App';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;

export default function ModalNodeEdit(props) {
  const { t } = useTranslation();
  const { modal } = App.useApp();
  const { ID, accessToken, Refresh_API } = useContext(AppContext);
  const { GetNodes, SelectedZone, TOOLS_MENU_LIST } = useContext(DesignContext);

  const [NodeContent, setNodeContent] = useState();
  const [ModalOpen, setModalOpen] = useState(false);

  const NODE_PROCESS = {
    NO_GROUP: 0,
    EMPTY_NODE: 1,
    ALLOCATED: 2,
    SET: 3
  };

  const onStop_DragNode = (e, data) => {
    const delta_x_per = (data.x / props.DesignWidth) * 100;
    const delta_y_per = (data.y / props.DesignHeight) * 100;
    let new_x_per = props.pn.x + delta_x_per;
    let new_y_per = props.pn.y + delta_y_per;

    if (new_x_per > 100) {
      new_x_per = 100;
    }
    if (new_x_per < 0) {
      new_x_per = 0;
    }
    if (new_y_per > 100) {
      new_y_per = 100;
    }
    if (new_y_per < 0) {
      new_y_per = 0;
    }

    props.pn.x = new_x_per;
    props.pn.y = new_y_per;
    UpdateNode(new_x_per, new_y_per);
  };

  const onCancel_Modal = (e) => {
    setModalOpen(false);
  };

  const onClick_NodeZoneUpdate = () => {
    if (SelectedZone == null) {
      modal.warning({
        content: t('modal.design.edit_node.warnning.select_group'),
        okText: t('modal.button.ok')
      });
      return;
    }

    if (props.pn.process > NODE_PROCESS.ALLOCATED) {
      modal.confirm({
        content:
          <Space direction='vertical'>
            {t('modal.design.edit_node.warnning.cannot_edit.line_1')}
            {t('modal.design.edit_node.warnning.cannot_edit.line_2')}
          </Space>,
        okText: t('modal.button.ok')
      });
    } else {
      UpdateNode();
    }
  };

  const NodeBgColor = (process) => {
    switch (process) {
      case NODE_PROCESS.EMPTY_NODE:
        return '#B40404';

      case NODE_PROCESS.ALLOCATED:
        return '#088A29';

      case NODE_PROCESS.SET:
        return '#177DDC';

      default:
        return '#BDBDBD';
    }
  };

  const NodeIcon = (pr) => {
    switch (pr) {
      case NODE_PROCESS.EMPTY_NODE:
        return (<CheckOutlined />);

      case NODE_PROCESS.ALLOCATED:
        return (<BulbOutlined />);

      case NODE_PROCESS.SET:
        return (<BulbFilled />);

      default:
        return (<PlusOutlined />);
    }
  };

  const NodeProcessString = (process) => {
    switch (process) {
      case NODE_PROCESS.NO_GROUP:
        return t('modal.design.edit_node.node_type.no_group');

      case NODE_PROCESS.EMPTY_NODE:
        return t('modal.design.edit_node.node_type.empty');

      case NODE_PROCESS.ALLOCATED:
        return t('modal.design.edit_node.node_type.allocated');

      case NODE_PROCESS.SET:
        return t('modal.design.edit_node.node_type.set');

      default:
        return '-';
    }
  };

  const UpdateNode = (new_x, new_y) => {
    let additional_data = {};
    if (props.EditMode === TOOLS_MENU_LIST.GROUP_EDIT) {
      additional_data.z_idx = SelectedZone.z_idx;
    } else if (props.EditMode === TOOLS_MENU_LIST.LOC_EDIT) {
      additional_data.x = new_x;
      additional_data.y = new_y;
    } else {
      return;
    }

    ApiMain(API_LIST.PROVISION_NODE_UPDATE, METHOD.POST, {
      id: ID,
      pn_idx: props.pn.pn_idx,
      ...additional_data
    }, accessToken).then((res) => {
      switch (res.status) {
        case API_RESPONSE_CODE.ACCEPTED:
          GetNodes();
          break;

        default:
          break;
      }
    }).catch((err) => {
      console.log(err);

      if (err.response.status === API_RESPONSE_CODE.PRECONDITION_FAILED) {
        Refresh_API();
      }
    });
  };

  const DeleteNode = () => {
    ApiMain(API_LIST.PROVISION_NODE_DELETE, METHOD.POST, {
      id: ID,
      pn_idx: props.pn.pn_idx,
    }, accessToken).then((res) => {
      switch (res.status) {
        case API_RESPONSE_CODE.ACCEPTED:
          GetNodes();
          setModalOpen(false);
          break;

        default:
          break;
      }
    }).catch((err) => {
      console.log(err);

      switch (err.response.status) {
        case API_RESPONSE_CODE.PRECONDITION_FAILED:
          Refresh_API();
          break;

        case API_RESPONSE_CODE.FORBIDDEN:
          modal.error({
            content: t('modal.design.edit_node.warnning.cannot_delete')
          });
          break;

        default:
          break;
      }
    });
  };

  const NodeStyle = (pn, mode) => {
    const size = (SelectedZone == null) ? 30 : (SelectedZone.z_idx === pn.z_idx) ? 30 : 15;

    return {
      width: size,
      height: size,
      color: '#FFFFFF',
      border: 'solid ' + ((SelectedZone == null) ? '2px #FFFFFF' : (SelectedZone.z_idx === pn.z_idx) ? '3px #F4BD40' : '1px #FFFFFF'),
      borderRadius: '50%',
      fontSize: size / 2,
      left: `calc(${pn.x}% - ${size / 2}px)`,
      top: `calc(${pn.y}% - ${size / 2}px)`,
      background: NodeBgColor(pn.process),
      position: 'absolute',
      cursor: (mode === TOOLS_MENU_LIST.NORMAL) ? 'zoom-in'
        : ((mode === TOOLS_MENU_LIST.NODE_ADD) || (mode === TOOLS_MENU_LIST.GROUP_EDIT) || (mode === TOOLS_MENU_LIST.DELETE)) ? 'pointer'
          : (mode === TOOLS_MENU_LIST.LOC_EDIT) ? 'move' : null
    };
  };

  const SetNodeContent = (mode) => {
    switch (mode) {
      case TOOLS_MENU_LIST.NORMAL:
        setNodeContent(
          <Tooltip title={
            <Space direction='vertical' size={1}>
              <Space>
                <Tag className='tag_node' color='#F4BD40' style={{ color: '#222222' }}>ID</Tag>
                <Text>{(props.pn.device_uuid != null) ? (props.pn.device_uuid.substring(0, 4) + '-' + props.pn.device_uuid.substring(34, 36)) : t('modal.design.edit_node.tag.no_id')}</Text>
              </Space>
              <Space>
                <Tag className='tag_node' color='#F4BD40' style={{ color: '#222222' }}>{t('modal.design.edit_node.tag.group')}</Tag>
                <Text>{(props.pn.z_name != null) ? props.pn.z_name : t('modal.design.edit_node.tag.no_group')}</Text>
              </Space>
            </Space>}>
            <div className='space_center' style={NodeStyle(props.pn, mode)} onClick={() => setModalOpen(true)}>{NodeIcon(props.pn.process)}</div>
          </Tooltip>
        );
        break;

      case TOOLS_MENU_LIST.NODE_ADD:
        setNodeContent(
          <div className='space_center' style={NodeStyle(props.pn, mode)}>{NodeIcon(props.pn.process)}</div>
        );
        break;

      case TOOLS_MENU_LIST.GROUP_EDIT:
        setNodeContent(
          <Tooltip title={
            <Space direction='vertical' size={0}>
              {(props.pn.z_name != null) ? (t('modal.design.edit_node.tag.group') + ': ' + props.pn.z_name) : ''}
              {(props.pn.device_uuid != null) ? (t('modal.design.edit_node.tag.light') + ': ' + props.pn.device_uuid.substring(0, 4) + '-' + props.pn.device_uuid.substring(34, 36)) : ''}
            </Space>}>
            <div className='space_center' style={NodeStyle(props.pn, mode)} onClick={onClick_NodeZoneUpdate}>{NodeIcon(props.pn.process)}</div>
          </Tooltip>
        );
        break;

      case TOOLS_MENU_LIST.LOC_EDIT:
        setNodeContent(
          <Draggable bounds='parent' grid={[1, 1]} scale={1} position={{ x: 0, y: 0 }} onStop={onStop_DragNode}>
            <div className='space_center' style={NodeStyle(props.pn, mode)}>{NodeIcon(props.pn.process)}</div>
          </Draggable>
        );
        break;

      case TOOLS_MENU_LIST.DELETE:
        setNodeContent(
          <div className={'space_center'} style={NodeStyle(props.pn, mode)} onClick={DeleteNode}>{NodeIcon(props.pn.process)}</div>
        );
        break;

      default:
        setNodeContent(null);
        break;
    }
  };

  useEffect(() => {
    SetNodeContent(props.EditMode);
  }, [props.pn, props.EditMode, SelectedZone]);

  return (
    <>
      {NodeContent}

      <Modal closable={false} open={ModalOpen} onCancel={onCancel_Modal} footer={null} centered={true}>
        <Space direction='vertical' size={1}>
          <Space>
            <Tag className='tag_node' color='#F4BD40' style={{ color: '#222222' }}>{t('modal.design.edit_node.tag.status')}</Tag>
            <Text>{(props.pn.process != null) ? NodeProcessString(props.pn.process) : '-'}</Text>
          </Space>
          <Space>
            <Tag className='tag_node' color='#F4BD40' style={{ color: '#222222' }}>{t('modal.design.edit_node.tag.group_name')}</Tag>
            <Text>{(props.pn.z_name != null) ? props.pn.z_name : t('modal.design.edit_node.tag.no_group')}</Text>
          </Space>

          <Divider className='divider_normal' />

          {(props.pn.process > NODE_PROCESS.EMPTY_NODE) ?
            <>
              <Space>
                <Tag className='tag_node' color='#F4BD40' style={{ color: '#222222' }}>{t('modal.design.edit_node.tag.full_id')}</Tag>
                <Text>{props.pn.device_uuid}</Text>
              </Space>
              {(props.pn.process > NODE_PROCESS.ALLOCATED) ?
                <>
                  <Space>
                    <Tag className='tag_node' color='#F4BD40' style={{ color: '#222222' }}>{t('modal.design.edit_node.tag.model_name')}</Tag>
                    <Text>{props.pn.part_number}</Text>
                  </Space>
                  <Space>
                    <Tag className='tag_node' color='#F4BD40' style={{ color: '#222222' }}>{t('modal.design.edit_node.tag.serial_number')}</Tag>
                    <Text>{props.pn.serial_number}</Text>
                  </Space>
                  <Space>
                    <Tag className='tag_node' color='#F4BD40' style={{ color: '#222222' }}>{t('modal.design.edit_node.tag.manufactured_date')}</Tag>
                    <Text>{props.pn.manufactured_date.substring(0, 10)}</Text>
                  </Space>
                </> : null}
            </> : null}
        </Space>
      </Modal >
    </>
  );
}