import { App, Input, Modal, Space, Tag, Typography } from "antd";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { API_LIST, API_RESPONSE_CODE, ApiMain, METHOD } from "../../common/Api";
import { AppContext } from "../../App";

const { Text } = Typography;

export default function ModalPasswordInit() {
  const { t } = useTranslation();
  const { accessToken, Refresh_API } = useContext(AppContext);
  const { modal } = App.useApp();

  const [ModalOpen, setModalOpen] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [Email, setEmail] = useState('');
  const [EmailWarnning, setEmailWarnning] = useState(false);

  const onClick_Modal = () => {
    setEmail('');
    setModalOpen(true);
  };

  const onCancel_Modal = () => {
    setModalOpen(false);
  };

  const onOk_Modal = () => {
    if (Email.length === 0) {
      setEmailWarnning(true);
      return;
    }

    setLoading(true);
    ApiMain(API_LIST.PW_INIT_REQUEST, METHOD.POST, {
      id: Email
    }, accessToken).then((res) => {
      switch (res.status) {
        case API_RESPONSE_CODE.OK:
          modal.success({
            content: t('modal.password_init.complete'),
            okText: t('modal.button.ok'),
            onOk: () => {
              setModalOpen(false);
            }
          });
          break;
        
        case API_RESPONSE_CODE.NO_CONTENT:
          modal.warning({
            content: t('modal.password_init.warnning.no_account'),
            okText: t('modal.button.ok')
          });
          break;

        default:
          modal.warning({
            content: t('modal.common.warnning.unkown_response') + res.status,
            okText: t('modal.button.ok')
          });
          break;
      }

      setLoading(false);
    }).catch((err) => {
      switch (err.response.status) {
        case API_RESPONSE_CODE.PRECONDITION_FAILED:
          Refresh_API();
          break;

        case API_RESPONSE_CODE.UNAUTHORIZED:
          modal.error({
            content: t('modal.common.warnning.unauthorized'),
            okText: t('modal.button.ok')
          });
          break;

        case API_RESPONSE_CODE.NOT_ACCEPTABLE:
          modal.error({
            content: t('modal.common.warnning.not_acceptable'),
            okText: t('modal.button.ok')
          });
          break;

        case API_RESPONSE_CODE.BAD_REQUEST:
          modal.error({
            content: t('modal.common.warnning.bad_request'),
            okText: t('modal.button.ok')
          });
          break;

        case API_RESPONSE_CODE.SERVICE_UNAVAILABLE:
          modal.error({
            content: t('modal.common.warnning.server_error'),
            okText: t('modal.button.ok')
          });
          break;

        default:
          modal.error({
            content: t('modal.common.warnning.unknown_error') + err.response.status,
            okText: t('modal.button.ok')
          });
          break;
      }

      setLoading(false);
    });
  };

  const onChange_Email = (e) => {
    setEmailWarnning(false);
    setEmail(e.target.value);
  };

  return (
    <>
      <Typography.Link onClick={onClick_Modal}>
        <Text>{t('modal.password_init.title')}</Text>
      </Typography.Link>

      <Modal closable={false} open={ModalOpen} confirmLoading={Loading} onOk={onOk_Modal} onCancel={onCancel_Modal} okText={t('modal.button.ok')} cancelText={t('modal.button.cancel')}
        title={
          <Space>
            {t('modal.password_init.title')}
          </Space>}>
        <Space direction='vertical space_center'>
          <Space className='modal_space_warnning'>
            <Tag className='modal_subtitle' color='#F4BD40' style={{ color: '#222222' }}>{t('modal.password_init.email')}</Tag>
            <Space direction='vertical' size={0}>
              <Input className='modal_input' value={Email} onChange={onChange_Email} />
              {EmailWarnning ? <Text className='modal_error_text'>{t('modal.password_init.warnning.no_email')}</Text> : null}
            </Space>
          </Space>
        </Space>
      </Modal>
    </>
  );
}