import React, { useState, useEffect, useContext, createContext } from 'react';
import { Space, Table, Select, Typography, Tag, Divider } from 'antd';
import ModalRoleCreate from '../components/role/ModalRoleCreate';
import ModalRoleDelete from '../components/role/ModalRoleDelete';
import { ProjectContext } from './Projects';
import { API_LIST, API_RESPONSE_CODE, ApiMain, METHOD } from '../common/Api';
import { AppContext } from '../App';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;

export const RoleContext = createContext();

export default function Role() {
  const { t } = useTranslation();
  const { ID, accessToken, Refresh_API } = useContext(AppContext);

  const { SelectedProject, SelectedBuilding } = useContext(ProjectContext);
  const [Roles, setRoles] = useState([]);

  const onChange_RoleUpdate = (record) => (value) => {
    ApiMain(API_LIST.USER_ROLE_SET, METHOD.POST, {
      id: ID,
      target: record.id,
      role: value,
      bldg_idx: SelectedBuilding.bldg_idx
    }, accessToken).then((res) => {
      GetRoles();
    }).catch((err) => {
      console.log(err);
      GetRoles();

      if (err.response.status === API_RESPONSE_CODE.PRECONDITION_FAILED) {
        Refresh_API();
      }
    });
  };

  const GetRoles = () => {
    setRoles([]);

    ApiMain(API_LIST.USER_ROLE_GET, METHOD.POST, {
      bldg_idx: SelectedBuilding.bldg_idx
    }, accessToken).then((res) => {
      switch (res.status) {
        case API_RESPONSE_CODE.OK:
          let roles = [];
          res.data.forEach((e, i) => {
            e.key = i;
            roles.push(e);
          });
          setRoles(roles);
          break;

        default:
          console.log(res);
          break;
      }
    }).catch((err) => {
      console.log(err);
      setRoles([]);

      if (err.response.status === API_RESPONSE_CODE.PRECONDITION_FAILED) {
        Refresh_API();
      }
    });
  };

  useEffect(() => {
    setRoles([]);
    if (SelectedBuilding != null) {
      GetRoles();
    }
  }, [SelectedBuilding]);

  return (
    <div className='role'>
      <RoleContext.Provider value={{ GetRoles }}>

        <Space direction='vertical'>
          <Space direction='vertical'>
            <Text className='title'>{t('role.title')}</Text>
            <Space direction='vertical' size={0}>
              <Text className='description'>{t('role.description_line_1')}</Text>
              <Text className='description'>{t('role.description_line_2')}</Text>
            </Space>
            <Space direction='vertical' size={3}>
              <Space>
                <Tag className='tag_node' color='magenta'>{t('role.type.owner')}</Tag>
                <Text className='subdescription'>{t('role.description_owner')}</Text>
              </Space>
              <Space>
                <Tag className='tag_node' color='blue'>{t('role.type.manager')}</Tag>
                <Text className='subdescription'>{t('role.description_manager')}</Text>
              </Space>
              <Space>
                <Tag className='tag_node' color='cyan'>{t('role.type.user')}</Tag>
                <Text className='subdescription'>{t('role.description_user')}</Text>
              </Space>
            </Space>
          </Space>

          <Divider className='divider_description' />

          <Space direction='vertical'>
            <Space className='space_project'>
              <Space>
                <Tag className='tag_node' color='#F4BD40' style={{ color: '#222222' }}>{t('role.project')}</Tag>
                <Text>{((SelectedProject != null) ? SelectedProject.pj_name : '')}</Text>
                <Divider className='divider_project' type='vertical' />
                <Tag className='tag_node' color='#F4BD40' style={{ color: '#222222' }}>{t('role.building')}</Tag>
                <Text>{((SelectedBuilding != null) ? SelectedBuilding.bldg_name : '')}</Text>
              </Space>
              <ModalRoleCreate bldg={SelectedBuilding} />
            </Space>
            <Table className='table' dataSource={Roles} pagination={false} locale={{ emptyText: t('role.no_data') }}
              columns={[{
                key: 'id',
                dataIndex: 'id',
                title: <Text className='table_header'>{t('role.table_column_title.email')}</Text>,
                render: (text) => <Space className='table_row'>{text}</Space>
              }, {
                key: 'company',
                dataIndex: 'company',
                title: <Text className='table_header'>{t('role.table_column_title.company')}</Text>,
                render: (text) => <Space className='table_row'>{text}</Space>
              }, {
                key: 'name',
                dataIndex: 'name',
                title: <Text className='table_header'>{t('role.table_column_title.name')}</Text>,
                render: (text) => <Space className='table_row'>{text}</Space>
              }, {
                key: 'role',
                dataIndex: 'role',
                title: <Text className='table_header'>{t('role.table_column_title.authority')}</Text>,
                render: (text, record) => (
                  (record.role === 0) ? (
                    <Tag className='tag_node' color='magenta'>{t('role.type.owner')}</Tag>
                  ) : (
                    <Select className='table_row_select' value={record.role} onChange={onChange_RoleUpdate(record)} disabled={(((SelectedBuilding != null) ? SelectedBuilding.role : 99) < 2) ? false : true}
                      options={[{
                        key: 1,
                        value: 1,
                        label: <Tag className='tag_node' color='blue'>{t('role.type.manager')}</Tag>
                      }, {
                        key: 2,
                        value: 2,
                        label: <Tag className='tag_node' color='cyan'>{t('role.type.user')}</Tag>
                      }]} />
                  )),
              }, {
                key: 'delete',
                title: <Text className='table_header'>{t('role.table_column_title.delete')}</Text>,
                render: (text, record) => <ModalRoleDelete disable={(record.role === 0) ? true : (((SelectedBuilding != null) ? SelectedBuilding.role : 99) < 2) ? false : true} bldg={SelectedBuilding} target={record.id} />,
              }]} />
          </Space>
        </Space>

      </RoleContext.Provider>
    </div>
  );
}