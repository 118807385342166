import { Button } from "antd";
import { useDaumPostcodePopup } from "react-daum-postcode";

export default function Postcode(props) {
  const open = useDaumPostcodePopup("https://t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js");

  const onComplete = (data) => {
    let fullAddress = data.address;
    let extraAddress = '';

    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname;
      }
      if (data.buildingName !== '') {
        extraAddress += extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
    }

    props.complete(fullAddress);
  };

  return (
    <Button onClick={() => open({ onComplete: onComplete })}>주소검색</Button>
  );
}