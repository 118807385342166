import React, { useContext, useState } from 'react';
import { App, Modal, Input, Button, Select, Typography, Space, Tag } from 'antd';
import { PlusOutlined, PlusSquareOutlined } from '@ant-design/icons';
import { RoleContext } from '../../pages/Role';
import { API_LIST, API_RESPONSE_CODE, ApiMain, METHOD } from '../../common/Api';
import { AppContext } from '../../App';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;

export default function ModalRoleCreate(props) {
  const EMAIL_REGEXP = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;
  const USER_AUTHORITY = {
    OWNER: 0,
    MANAGER: 1,
    END_USER: 2
  };

  const { t } = useTranslation();
  const { modal } = App.useApp();
  const { ID, accessToken, Refresh_API } = useContext(AppContext);
  const { GetRoles } = useContext(RoleContext);

  const [Email, setEmail] = useState('');
  const [Role, setRole] = useState(USER_AUTHORITY.END_USER);
  const [ModalOpen, setModalOpen] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [EmailWarnning, setEmailWarnning] = useState(false);

  const onClick_Modal = () => {
    setEmailWarnning(false);
    setEmail('');
    setRole(USER_AUTHORITY.END_USER);
    setModalOpen(true);
  };

  const onCancel_Modal = () => {
    setModalOpen(false);
  };

  const onOk_Modal = (e) => {
    if (Email.match(EMAIL_REGEXP) == null) {
      setEmailWarnning(true);
      return;
    }

    setLoading(true);
    ApiMain(API_LIST.USER_ROLE_SET, METHOD.POST, {
      id: ID,
      bldg_idx: props.bldg.bldg_idx,
      target: Email,
      role: Role
    }, accessToken).then((res) => {
      switch (res.status) {
        case API_RESPONSE_CODE.CREATED:
          modal.success({
            content: t('modal.role.create.complete'),
            okText: t('modal.button.ok'),
            onOk: () => {
              GetRoles();
              setModalOpen(false);
            }
          });
          break;

        default:
          modal.warning({
            content: t('modal.common.warnning.unkown_response') + res.status,
            okText: t('modal.button.ok')
          });
          break;
      }
      setLoading(false);
    }).catch((err) => {
      switch (err.response.status) {
        case API_RESPONSE_CODE.PRECONDITION_FAILED:
          Refresh_API();
          break;
          
        case API_RESPONSE_CODE.UNAUTHORIZED:
          modal.error({
            content: t('modal.role.create.warnning.unauthorized'),
            okText: t('modal.button.ok')
          });
          break;

        case API_RESPONSE_CODE.FORBIDDEN:
          modal.error({
            content: t('modal.role.create.warnning.forbidden'),
            okText: t('modal.button.ok')
          });
          break;

        case API_RESPONSE_CODE.NOT_ACCEPTABLE:
          modal.error({
            content: t('modal.role.create.warnning.cannot_enroll'),
            okText: t('modal.button.ok')
          });
          break;

        case API_RESPONSE_CODE.BAD_REQUEST:
          modal.error({
            content: t('modal.common.warnning.bad_request'),
            okText: t('modal.button.ok')
          });
          break;

        case API_RESPONSE_CODE.SERVICE_UNAVAILABLE:
          modal.error({
            content: t('modal.common.warnning.server_error'),
            okText: t('modal.button.ok')
          });
          break;

        default:
          modal.error({
            content: t('modal.common.warnning.unknown_error') + err.response.status,
            okText: t('modal.button.ok')
          });
          break;
      }
      setLoading(false);
    });
  };

  const onChange_Email = (e) => {
    setEmail(e.target.value);
    setEmailWarnning(false);
  };

  const onChange_Role = (value) => {
    setRole(value);
  };

  return (
    <>
      <Button type='primary' icon={<PlusOutlined />} style={{ borderRadius: 10 }} onClick={onClick_Modal}>{t('role.button.add_user')}</Button>

      <Modal closable={false} open={ModalOpen} confirmLoading={Loading} onOk={onOk_Modal} onCancel={onCancel_Modal} okText={t('modal.button.ok')} cancelText={t('modal.button.cancel')}
        title={
          <Space>
            <PlusSquareOutlined />
            {t('modal.role.create.title')}
          </Space>}>
        <Space className='modal_content space_center' direction='vertical'>
          <Space className='modal_space_warnning'>
            <Tag className='modal_subtitle' color='#F4BD40' style={{ color: '#222222' }}>{t('modal.role.create.user_email')}</Tag>
            <Space direction='vertical' size={0}>
              <Input className='modal_input' value={Email} onChange={onChange_Email} />
              {EmailWarnning ? <Text className='modal_error_text'>{t('modal.role.create.warnning.format_error')}</Text> : null}
            </Space>
          </Space>

          <Space>
            <Tag className='modal_subtitle' color='#F4BD40' style={{ color: '#222222' }}>{t('modal.role.create.authority')}</Tag>
            <Select className='modal_input' value={Role} onChange={onChange_Role}
              options={[{
                key: USER_AUTHORITY.MANAGER,
                value: USER_AUTHORITY.MANAGER,
                label: <Tag className='tag_node' color='blue'>{t('role.type.manager')}</Tag>
              }, {
                key: USER_AUTHORITY.END_USER,
                value: USER_AUTHORITY.END_USER,
                label: <Tag className='tag_node' color='cyan'>{t('role.type.user')}</Tag>
              }]} />
          </Space>
        </Space>
      </Modal>
    </>
  );
}