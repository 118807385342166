export const BRAND_URL = [{
  brand: 'dmuseum',
  url: '/dmuseum',
  header_logo: '/img/dmuseum_small_logo.png',
  small_logo: '/img/dmuseum_small_logo.png',
  brand_logo: '/img/dmuseum_brand_logo.png'
}, {
  brand: 'emblaze',
  url: '',
  header_logo: '/img/emblaze.svg',
  small_logo: '/img/neostack.png',
  brand_logo: '/img/emblaze.svg'
}];