import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import languageEn from './language.en';
import languageKo from './language.ko';

const resource = {
  en: { translation: languageEn },
  ko: { translation: languageKo }
};

i18n.use(initReactI18next)
  .init({
    resources: resource,
    lng: 'ko',
    fallbackLng: 'ko',
    debug: false,
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;