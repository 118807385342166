import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { Button, Input, Card, Checkbox, Tabs, Space, Image, Typography, App } from 'antd';
import { MailOutlined, LockOutlined } from '@ant-design/icons';
import '../App.css';
import { AppContext } from '../App';
import { API_LIST, API_RESPONSE_CODE, ApiMain, METHOD } from '../common/Api';
import { BRAND_URL } from '../common/Brand';
import ModalLanguageChange from '../components/language/ModalLanguageChange';
import ModalPasswordInit from '../components/user/ModalPasswordInit';

const { Text } = Typography;

export default function Login() {
  const { t } = useTranslation();
  const [cookies, setCookie, removeCookie] = useCookies();
  const navigate = useNavigate();
  const { setId, setAccessToken, setRefreshToken } = useContext(AppContext);
  const { modal } = App.useApp();

  const [SignInID, setSignInID] = useState(cookies.signin_id);
  const [SignInPW, setSignInPW] = useState('');
  const [SignUpID, setSignUpID] = useState('');
  const [RememberID, setRememberID] = useState(cookies.remember_id ? true : false);
  const [BrandLogo, setBrandLogo] = useState('');

  const onClick_SignUp = () => {
    if (SignUpID.length === 0) {
      modal.warning({
        content: t('modal.sign_up.warnning.input_email'),
        okText: t('modal.button.ok')
      });
      return;
    }

    ApiMain(API_LIST.SIGN_UP_REQUEST, METHOD.POST, {
      sign_up_method: 0,
      email: SignUpID
    }).then((res) => {
      switch (res.status) {
        case API_RESPONSE_CODE.OK:
          modal.success({
            content: t('modal.sign_up.sent_email'),
            okText: t('modal.button.ok')
          });
          break;

        default:
          modal.warning({
            content: t('modal.common.warnning.unkown_response') + res.status,
            okText: t('modal.button.ok')
          });
          break;
      }
    }).catch((err) => {
      switch (err.response.status) {
        case API_RESPONSE_CODE.BAD_REQUEST:
          modal.error({
            content: t('modal.sign_up.warnning.no_support_method'),
            okText: t('modal.button.ok')
          });
          break;

        case API_RESPONSE_CODE.NOT_ACCEPTABLE:
          modal.warning({
            content: t('modal.sign_up.warnning.exist_account'),
            okText: t('modal.button.ok')
          });
          break;

        case API_RESPONSE_CODE.SERVICE_UNAVAILABLE:
          modal.error({
            content: t('modal.common.warnning.server_error'),
            okText: t('modal.button.ok')
          });
          break;

        default:
          modal.error({
            content: t('modal.common.warnning.unknown_error') + err.response.status,
            okText: t('modal.button.ok')
          });
          break;
      }
    });
  };

  const onClick_SignIn = () => {
    if ((SignInID.length === 0) || (SignInPW.length === 0)) {
      modal.warning({
        content: t('modal.sign_in.warnning.input_email_password'),
        okText: t('modal.button.ok')
      });
      return;
    }

    if (RememberID) {
      setCookie('remember_id', true, { path: '/', maxAge: 4838400 });
      setCookie('signin_id', SignInID, { path: '/', maxAge: 4838400 });
    } else {
      removeCookie('remember_id');
      removeCookie('signin_id');
    }

    ApiMain(API_LIST.SIGN_IN, METHOD.POST, {
      id: SignInID,
      pw: SignInPW
    }).then((res) => {
      switch (res.status) {
        case API_RESPONSE_CODE.CREATED:
          window.sessionStorage.setItem('pj_idx', res.data.pj_idx);
          window.sessionStorage.setItem('bldg_idx', res.data.bldg_idx);
          window.sessionStorage.setItem('f_idx', res.data.f_idx);

          modal.info({
            content: t('modal.sign_in.warnning.retry_sign_in'),
            okText: t('modal.button.ok')
          });
          break;

        case API_RESPONSE_CODE.ACCEPTED:
          window.sessionStorage.setItem('pj_idx', res.data.pj_idx);
          window.sessionStorage.setItem('bldg_idx', res.data.bldg_idx);
          window.sessionStorage.setItem('f_idx', res.data.f_idx);

          if (res.headers.hasOwnProperty('emblaze-at-v1')) {
            setAccessToken(res.headers['emblaze-at-v1']);
            setRefreshToken(res.headers['emblaze-rt-v1']);
            window.sessionStorage.setItem('accessToken', res.headers['emblaze-at-v1']);
            window.sessionStorage.setItem('refreshToken', res.headers['emblaze-rt-v1']);
          }

          window.sessionStorage.setItem('loginDatetime', new Date());
          window.sessionStorage.setItem('signInId', SignInID);
          setId(SignInID);

          navigate((!window.location.pathname.includes('dmuseum') ? '/projects' : '/dmuseum/projects'), { replace: true });
          break;

        case API_RESPONSE_CODE.NO_CONTENT:
          modal.warning({
            content: t('modal.sign_in.warnning.no_account'),
            okText: t('modal.button.ok')
          });
          break;

        default:
          modal.warning({
            content: t('modal.common.warnning.unkown_response') + res.status,
            okText: t('modal.button.ok')
          });
          break;
      }
    }).catch((err) => {
      switch (err.response.status) {
        case API_RESPONSE_CODE.UNAUTHORIZED:
          modal.warning({
            content: t('modal.sign_in.warnning.check_id_password'),
            okText: t('modal.button.ok')
          });
          break;

        case API_RESPONSE_CODE.FORBIDDEN:
          modal.warning({
            content: t('modal.sign_in.warnning.under_authentication'),
            okText: t('modal.button.ok')
          });
          break;

        case API_RESPONSE_CODE.BAD_REQUEST:
          modal.error({
            content: t('modal.common.warnning.bad_request'),
            okText: t('modal.button.ok')
          });
          break;

        case API_RESPONSE_CODE.SERVICE_UNAVAILABLE:
          modal.warning({
            content: t('modal.common.warnning.server_error'),
            okText: t('modal.button.ok')
          });
          break;

        default:
          modal.error({
            content: t('modal.common.warnning.unknown_error') + err.response.status,
            okText: t('modal.button.ok')
          });
          break;
      }
    });
  };

  const onKeyDown_SignIn = (e) => {
    if (e.key === 'Enter') {
      onClick_SignIn();
    }
  };

  const onChange_SignInID = (e) => {
    setSignInID(e.target.value);
  };

  const onChange_SignInPW = (e) => {
    setSignInPW(e.target.value);
  };

  const onChange_SignUpID = (e) => {
    setSignUpID(e.target.value);
  };

  const onChange_RememberID = (e) => {
    setRememberID(e.target.checked);
  };

  useEffect(() => {
    for (const e of BRAND_URL) {
      if (window.location.pathname.includes(e.url)) {
        setBrandLogo(e.brand_logo);
        break;
      }
    }
  }, []);

  return (
    <div className='login space_center'>
      <Space size={200}>
        <Space direction='vertical'>
          <Image width={230} preview={false} src={BrandLogo} />
          <Text className='title'>Dashboard</Text>
          <Text className='subtitle'>Connected Light Control Platform</Text>
        </Space>

        <Card>
          <Tabs defaultActiveKey={0}
            items={[{
              key: 0,
              label: t('login.tab.sign_in'),
              children:
                <Space className='card_tab_child' direction='vertical' size={15}>
                  <Input value={SignInID} size='large' placeholder={t('login.placeholder.email')} prefix={<MailOutlined className='icon' />} onChange={onChange_SignInID} />
                  <Input.Password value={SignInPW} size='large' placeholder={t('login.placeholder.password')} prefix={<LockOutlined className='icon' />} onChange={onChange_SignInPW} onKeyDown={onKeyDown_SignIn} />
                  <Space className='card_space_between'>
                    <Checkbox checked={RememberID} onChange={onChange_RememberID}>{t('login.checkbox.remember_email')}</Checkbox>
                    <ModalLanguageChange type={1} />
                  </Space>
                  <Button className='button' type='primary' size='large' block onClick={onClick_SignIn}>{t('login.button.sign_in')}</Button>
                  <Space className='space_center' style={{ marginTop: 20 }}>
                    <ModalPasswordInit />
                  </Space>
                </Space>
            }, {
              key: 1,
              label: t('login.tab.sign_up'),
              children:
                <Space className='card_tab_child' direction='vertical' size={15}>
                  <Input value={SignUpID} size='large' placeholder={t('login.placeholder.email')} prefix={<MailOutlined className='icon' />} onChange={onChange_SignUpID} />
                  <Button className='button' type='primary' size='large' block onClick={onClick_SignUp}>{t('login.button.sign_up')}</Button>
                </Space>
            }]} />
        </Card>
      </Space>
    </div>
  );
}