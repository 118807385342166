import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Input, Button, Typography, Space, Tooltip, Tag, App } from 'antd';
import { PlusOutlined, PlusSquareOutlined } from '@ant-design/icons';
import { ProjectContext } from '../../pages/Projects';
import { API_LIST, API_RESPONSE_CODE, ApiMain, METHOD } from '../../common/Api';
import { AppContext } from '../../App';
import Postcode from '../../common/Postcode';

const { Text } = Typography;

export default function ModalBuildingCreate() {
  const { t, i18n } = useTranslation();
  const { modal } = App.useApp();
  const { ID, accessToken, Refresh_API } = useContext(AppContext);
  const { SelectedProject, onReload_Building } = useContext(ProjectContext);

  const [ModalOpen, setModalOpen] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [BuildingName, setBuildingName] = useState('');
  const [BuildingAddress, setBuildingAddress] = useState('');
  const [BuildingNameWarnning, setBuildingNameWarnning] = useState(false);
  const [BuildingAddressWarnning, setBuildingAddressWarnning] = useState(false);

  const onClick_Modal = () => {
    setBuildingNameWarnning(false);
    setBuildingAddressWarnning(false);
    setModalOpen(true);
  };

  const onCancel_Modal = (e) => {
    setModalOpen(false);
  };

  const onOk_Modal = (e) => {
    if ((BuildingName.length === 0) || (BuildingAddress.length === 0)) {
      if (BuildingName.length === 0) {
        setBuildingNameWarnning(true);
      }
      if (BuildingAddress.length === 0) {
        setBuildingAddressWarnning(true);
      }
      return;
    }

    setLoading(true);
    ApiMain(API_LIST.BUILDING_CREATE, METHOD.POST, {
      id: ID,
      pj_idx: SelectedProject.pj_idx,
      bldg_name: BuildingName,
      address: BuildingAddress
    }, accessToken).then((res) => {
      switch (res.status) {
        case API_RESPONSE_CODE.CREATED:
          modal.success({
            content: t('modal.building.create.complete'),
            okText: t('modal.button.ok'),
            onOk: () => {
              onReload_Building(res.data);
              setModalOpen(false);
            }
          });
          break;

        default:
          modal.warning({
            content: t('modal.common.warnning.unkown_response') + res.status,
            okText: t('modal.button.ok')
          });
          break;
      }

      setLoading(false);
    }).catch((err) => {
      switch (err.response.status) {
        case API_RESPONSE_CODE.PRECONDITION_FAILED:
          Refresh_API();
          break;
        
        case API_RESPONSE_CODE.UNAUTHORIZED:
          modal.error({
            content: t('modal.common.warnning.unauthorized'),
            okText: t('modal.button.ok')
          });
          break;

        case API_RESPONSE_CODE.NOT_ACCEPTABLE:
          modal.error({
            content: t('modal.common.warnning.not_acceptable'),
            okText: t('modal.button.ok')
          });
          break;

        case API_RESPONSE_CODE.BAD_REQUEST:
          modal.error({
            content: t('modal.common.warnning.bad_request'),
            okText: t('modal.button.ok')
          });
          break;

        case API_RESPONSE_CODE.SERVICE_UNAVAILABLE:
          modal.error({
            content: t('modal.common.warnning.server_error'),
            okText: t('modal.button.ok')
          });
          break;

        default:
          modal.error({
            content: t('modal.common.warnning.unknown_error') + err.response.status,
            okText: t('modal.button.ok')
          });
          break;
      }

      setLoading(false);
    });
  };

  const onChange_BuildingName = (e) => {
    setBuildingName(e.target.value);
    setBuildingNameWarnning(false);
  };

  const onChange_BuildingAddress = (e) => {
    setBuildingAddress(e.target.value);
    setBuildingAddressWarnning(false);
  };

  const onComplete_Postcode = (address) => {
    setBuildingAddress(address);
  };

  return (
    <>
      <Tooltip title={t('project.menu.create_building')}>
        <Button size='large' icon={<PlusOutlined />} onClick={onClick_Modal}></Button>
      </Tooltip>

      <Modal closable={false} open={ModalOpen} confirmLoading={Loading} onOk={onOk_Modal} onCancel={onCancel_Modal} okText={t('modal.button.ok')} cancelText={t('modal.button.cancel')}
        title={
          <Space>
            <PlusSquareOutlined />
            {t('modal.building.create.title')}
          </Space>}>
        <Space direction='vertical space_center'>
          <Space className='modal_space_warnning'>
            <Tag className='modal_subtitle' color='#F4BD40' style={{ color: '#222222' }}>{t('modal.building.create.building_name')}</Tag>
            <Space direction='vertical' size={0}>
              <Input className='modal_input' value={BuildingName} onChange={onChange_BuildingName} />
              {BuildingNameWarnning ? <Text className='modal_error_text'>{t('modal.building.create.warnning.no_building_name')}</Text> : null}
            </Space>
          </Space>

          <Space className='modal_space_warnning'>
            <Tag className='modal_subtitle' color='#F4BD40' style={{ color: '#222222' }}>{t('modal.building.create.building_address')}</Tag>
            <Space direction='vertical' style={{ alignItems: 'end' }}>
              <Space direction='vertical' size={0}>
                <Input className='modal_input' value={BuildingAddress} onChange={onChange_BuildingAddress} />
                {BuildingAddressWarnning ? <Text className='modal_error_text'>{t('modal.building.create.warnning.no_building_address')}</Text> : null}
              </Space>
              {(i18n.language === 'ko') ? <Postcode complete={onComplete_Postcode} /> : null}
            </Space>
          </Space>
        </Space>
      </Modal>
    </>
  );
}