import React, { useState, useEffect, useContext, useRef, createContext } from 'react';
import { Select, Space, Typography, Tag, Card } from 'antd';
import { ProjectContext } from '../../pages/Projects';
import { API_LIST, API_RESPONSE_CODE, ApiMain, METHOD } from '../../common/Api';
import { AppContext } from '../../App';
import { useTranslation } from 'react-i18next';
import FieldImage from '../field/FieldImage';
import ModalNode from './ModalNode';

const { Text } = Typography;

export const MonitoringContext = createContext();

function useInterval(callback, delay_sec) {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    if (delay_sec == 0) {
      delay_sec = null;
    }

    function tick() {
      savedCallback.current();
    }

    if (delay_sec != null) {
      const id = setInterval(tick, delay_sec * 1000);
      return () => clearInterval(id);
    }
  }, [delay_sec]);
}

export default function Monitoring() {
  const { t } = useTranslation();
  const MonitoringRef = useRef(null);
  const { ID, accessToken, Refresh_API } = useContext(AppContext);
  const { Fields, SelectedProject, SelectedBuilding, RunningCount, SetViewHistory } = useContext(ProjectContext);

  const ZONE_SELECT = {
    all: 'all'
  };

  const [GatewayCheck, setGatewayCheck] = useState(false);
  const [SelectedField, setSelectedField] = useState(null);
  const [SelectedZone, setSelectedZone] = useState(ZONE_SELECT.all);
  const [UpdatePeriod, setUpdatePeriod] = useState(0);
  const [FieldImgURL, setFieldImgURL] = useState();
  const [Zones, setZones] = useState([]);
  const [Nodes, setNodes] = useState([]);
  const [FieldWidth, setFieldWidth] = useState(0);
  const [FieldHeight, setFieldHeight] = useState(0);

  const onChange_SelectField = (value) => {
    setSelectedField(value);
    window.sessionStorage.setItem('f_idx', value);
    SetViewHistory(SelectedProject.pj_idx, SelectedBuilding.bldg_idx, value);
  };

  const onLoad_FieldImage = () => {
    setFieldWidth(MonitoringRef.current.clientWidth);
    setFieldHeight(MonitoringRef.current.clientHeight);
  };

  const GetNodes = () => {
    setZones([]);
    setNodes([]);

    ApiMain(API_LIST.NODE_STATUS_GET, METHOD.POST, {
      id: ID,
      f_idx: SelectedField
    }, accessToken).then((res) => {
      switch (res.status) {
        case API_RESPONSE_CODE.OK:
          let node_list = [];
          res.data.forEach((e) => {
            e.pn.forEach((_e) => {
              _e.z_name = e.z_name;
              node_list.push(_e);
            });
          });

          setNodes(node_list);
          setZones(res.data);
          break;

        default:
          console.log(res.status);
          break;
      }
    }).catch((err) => {
      console.log(err);

      if (err.response.status === API_RESPONSE_CODE.PRECONDITION_FAILED) {
        Refresh_API();
      }
    });
  };

  const CheckAllocatedGateway = () => {
    ApiMain(API_LIST.ALLOCATED_GATEWAY_CHECK, METHOD.POST, {
      f_idx: SelectedField
    }, accessToken).then((res) => {
      switch (res.status) {
        case API_RESPONSE_CODE.OK:
          setGatewayCheck(true);
          break;

        default:
          console.log(res.status);
          setGatewayCheck(false);
          break;
      }
    }).catch((err) => {
      console.log(err);
      setGatewayCheck(false);

      if (err.response.status === API_RESPONSE_CODE.PRECONDITION_FAILED) {
        Refresh_API();
      }
    });
  };

  useEffect(() => {
    if (SelectedField != null) {
      for (let i = 0; i < Fields.length; i++) {
        if (SelectedField === Fields[i].f_idx) {
          setFieldImgURL(Fields[i].img_url);
          break;
        }
      }

      GetNodes();
      CheckAllocatedGateway();
    }
  }, [SelectedField]);

  useEffect(() => {
    setFieldImgURL(null);
    setGatewayCheck(false);
    setSelectedField(null);
    setSelectedZone(ZONE_SELECT.all);
    setZones([]);
    setNodes([]);
  }, [SelectedBuilding]);

  useEffect(() => {
    for (const e of Fields) {
      if (e.f_idx === parseInt(window.sessionStorage.f_idx)) {
        setSelectedField(e.f_idx);
        break;
      }
    }
  }, [Fields]);

  useInterval(() => {
    GetNodes();
  }, UpdatePeriod);

  return (
    <div className='monitoring'>
      <MonitoringContext.Provider value={{ selectedZone: SelectedZone, loadNodes: GetNodes }}>

        <Card title={<Text className='card_title'>{t('monitoring.field.title')}</Text>}>
          <Space className='space_center' direction='vertical'>
            <Space size={40}>
              <Space direction='vertical'>
                <Space>
                  <Tag className='space_center tag_subtitle' color='#F4BD40'>{t('monitoring.field.subtitle.select_field')}</Tag>
                  <Select className='select_sub' value={SelectedField} notFoundContent={t('monitoring.field.no_content')} placeholder={t('monitoring.field.select_field')} onChange={onChange_SelectField}
                    options={Fields.map((value, index) => ({
                      key: index,
                      value: value.f_idx,
                      label: '(' + value.f_idx + ') ' + value.f_name
                    }))} />
                </Space>
                {(SelectedField != null) ?
                  <Space>
                    <Tag className='space_center tag_subtitle' color='#F4BD40'>{t('monitoring.field.subtitle.running_lights')}</Tag>
                    <Tag className='space_center tag_subcontent'>
                      {(() => {
                        for (let i = 0; i < Fields.length; i++) {
                          if (SelectedField === Fields[i].f_idx) {
                            return RunningCount[i];
                          }
                        }
                        return 0;
                      })()}
                    </Tag>
                  </Space> : null}
              </Space>

              {((SelectedField != null) && (GatewayCheck)) ?
                <Space direction='vertical'>
                  <Space>
                    <Tag className='space_center tag_subtitle' color='#F4BD40'>{t('monitoring.field.subtitle.select_group')}</Tag>
                    <Select className='select_sub' value={SelectedZone} onChange={(value) => setSelectedZone(value)}
                      options={[{
                        key: -1,
                        label: t('monitoring.field.radio_button.all_group'),
                        value: ZONE_SELECT.all
                      },
                      ...Zones.map((value, index) => ({
                        key: index,
                        value: value.z_idx,
                        label: '(' + value.z_idx + ') ' + value.z_name
                      }))]} />
                  </Space>
                  <Space>
                    <Tag className='space_center tag_subtitle' color='#F4BD40'>{t('monitoring.field.subtitle.group_lights')}</Tag>
                    <Tag className='space_center tag_subcontent'>
                      {(SelectedZone === ZONE_SELECT.all) ? Nodes.length
                        : (() => {
                          let count = 0;
                          for (const e of Nodes) {
                            if (SelectedZone === e.z_idx) {
                              count += 1;
                            }
                          }
                          return count;
                        })()
                      }
                    </Tag>
                  </Space>
                </Space> : null}
            </Space>

            <Space style={{ marginTop: 10 }}>
              <Tag className='space_center tag_subtitle' color='#F4BD40'>새로고침 주기</Tag>
              <Select className='select_sub' value={UpdatePeriod} onChange={(value) => setUpdatePeriod(value)}
                options={[{
                  key: 0,
                  label: '갱신 없음',
                  value: 0
                }, {
                  key: 10,
                  label: '10초마다 갱신',
                  value: 10
                }, {
                  key: 30,
                  label: '30초마다 갱신',
                  value: 30
                }, {
                  key: 60,
                  label: '60초마다 갱신',
                  value: 60
                }]} />
            </Space>

            {(SelectedField != null) ?
              (!GatewayCheck) ? <Space className='non_field'>{t('monitoring.field.no_gateway')}</Space> :
                <Space className='space_center none_overflow'>
                  <div className='space_center field' ref={MonitoringRef}>
                    <FieldImage img_url={FieldImgURL} width={'100%'} height={'100%'} preview={false} onLoad={onLoad_FieldImage} />
                    {Nodes.map((node) => <ModalNode key={node.pn_idx} pn={node} highlight={false} designWidth={FieldWidth} designHeight={FieldHeight} />)}
                  </div>
                </Space> : null}
          </Space>
        </Card>

      </MonitoringContext.Provider >
    </div>
  );
}