import { useContext, useState } from 'react';
import { Modal, Input, Button, Space, Typography, Tag, App } from 'antd';
import { PlusSquareOutlined, PlusOutlined } from '@ant-design/icons';
import { DesignContext } from '../../pages/Design';
import { ProfileContext } from '../../pages/Profiles';
import { API_LIST, API_RESPONSE_CODE, ApiMain, METHOD } from '../../common/Api';
import { AppContext } from '../../App';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;

export default function ModalProfileCreate() {
  const { t } = useTranslation();
  const { ID, accessToken, Refresh_API } = useContext(AppContext);
  const { Zones } = useContext(DesignContext);
  const { GetProfiles } = useContext(ProfileContext);
  const { modal } = App.useApp();

  const [ModalOpen, setModalOpen] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [ProfileName, setProfileName] = useState('');
  const [ProfileNameWarnning, setProfileNameWarnning] = useState(false);

  const onClick_Modal = () => {
    setProfileName('');
    setProfileNameWarnning(false);
    setModalOpen(true);
  };

  const onCancel_Modal = () => {
    setModalOpen(false);
  };

  const onOk_Modal = () => {
    if (ProfileName.length === 0) {
      setProfileNameWarnning(true);
      return;
    }

    let i = 0;
    for (; i < Zones.length; i++) {
      if (Zones[i] != null) {
        break;
      }
    }

    setLoading(true);
    ApiMain(API_LIST.PROFILE_CREATE, METHOD.POST, {
      id: ID,
      pf_name: ProfileName,
      z_idx: Zones[i].z_idx
    }, accessToken).then((res) => {
      switch (res.status) {
        case API_RESPONSE_CODE.CREATED:
          modal.success({
            content: t('modal.profile.create.complete'),
            okText: t('modal.button.ok'),
            onOk: () => {
              GetProfiles(res.data.pf_idx);
              setModalOpen(false);
            }
          });
          break;

        default:
          modal.warning({
            content: t('modal.common.warnning.unkown_response') + res.status,
            okText: t('modal.button.ok')
          });
          break;
      }

      setLoading(false);
    }).catch((err) => {
      switch (err.response.status) {
        case API_RESPONSE_CODE.PRECONDITION_FAILED:
          Refresh_API();
          break;

        case API_RESPONSE_CODE.UNAUTHORIZED:
          modal.error({
            content: t('modal.common.warnning.unauthorized'),
            okText: t('modal.button.ok')
          });
          break;

        case API_RESPONSE_CODE.NOT_ACCEPTABLE:
          modal.error({
            content: t('modal.common.warnning.not_acceptable'),
            okText: t('modal.button.ok')
          });
          break;

        case API_RESPONSE_CODE.BAD_REQUEST:
          modal.error({
            content: t('modal.common.warnning.bad_request'),
            okText: t('modal.button.ok')
          });
          break;

        case API_RESPONSE_CODE.SERVICE_UNAVAILABLE:
          modal.error({
            content: t('modal.common.warnning.server_error'),
            okText: t('modal.button.ok')
          });
          break;

        default:
          modal.error({
            content: t('modal.common.warnning.unknown_error') + err.response.status,
            okText: t('modal.button.ok')
          });
          break;
      }

      setLoading(false);
    });
  };

  const onChange_ProfileName = (e) => {
    setProfileNameWarnning(false);
    setProfileName(e.target.value);
  };

  return (
    <>
      <Button icon={<PlusOutlined />} onClick={onClick_Modal}>{t('modal.profile.create.button')}</Button>

      <Modal closable={false} open={ModalOpen} confirmLoading={Loading} onOk={onOk_Modal} onCancel={onCancel_Modal} okText={t('modal.button.ok')} cancelText={t('modal.button.cancel')}
        title={
          <Space>
            <PlusSquareOutlined />
            {t('modal.profile.create.title')}
          </Space>}>
        <Space direction='vertical space_center'>
          <Space className='modal_space_warnning'>
            <Tag className='modal_subtitle' color='#F4BD40' style={{ color: '#222222' }}>{t('modal.profile.create.profile_name')}</Tag>
            <Space direction='vertical' size={0}>
              <Input className='modal_input' value={ProfileName} onChange={onChange_ProfileName} />
              {ProfileNameWarnning ? <Text className='modal_error_text'>{t('modal.profile.create.warnning.no_profile_name')}</Text> : null}
            </Space>
          </Space>
        </Space>
      </Modal>
    </>
  );
}