import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { App, Button, Input, Modal, Space, Tooltip } from 'antd';
import { DeleteOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { ProjectContext } from '../../pages/Projects';
import { API_LIST, API_RESPONSE_CODE, ApiMain, METHOD } from '../../common/Api';
import { AppContext } from '../../App';

export default function ModalBuildingDelete() {
  const { t } = useTranslation();
  const { modal } = App.useApp();
  const { ID, accessToken, Refresh_API } = useContext(AppContext);
  const { SelectedBuilding, onReload_Building } = useContext(ProjectContext);

  const [ModalOpen, setModalOpen] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [DeleteString, setDeleteString] = useState('');

  const onClick_Modal = () => {
    setModalOpen(true);
    setDeleteString('');
  };

  const onOk_Modal = () => {
    setLoading(true);
    ApiMain(API_LIST.BUILDING_DELETE, METHOD.POST, {
      id: ID,
      bldg_idx: SelectedBuilding.bldg_idx
    }, accessToken).then((res) => {
      switch (res.status) {
        case API_RESPONSE_CODE.ACCEPTED:
          modal.success({
            content: t('modal.building.delete.complete'),
            okText: t('modal.button.ok'),
            onOk: () => {
              onReload_Building();
              setModalOpen(false);
            }
          });
          break;

        default:
          modal.warning({
            content: t('modal.common.warnning.unkown_response') + res.status,
            okText: t('modal.button.ok')
          });
          break;
      }

      setLoading(false);
    }).catch((err) => {
      switch (err.response.status) {
        case API_RESPONSE_CODE.PRECONDITION_FAILED:
          Refresh_API();
          break;
        
        case API_RESPONSE_CODE.UNAUTHORIZED:
          modal.error({
            content: t('modal.common.warnning.unauthorized'),
            okText: t('modal.button.ok')
          });
          break;

        case API_RESPONSE_CODE.FORBIDDEN:
          modal.error({
            content: t('modal.building.delete.warnning.forbidden'),
            okText: t('modal.button.ok')
          });
          break;

        case API_RESPONSE_CODE.NOT_ACCEPTABLE:
          modal.error({
            content: t('modal.common.warnning.not_acceptable'),
            okText: t('modal.button.ok')
          });
          break;

        case API_RESPONSE_CODE.BAD_REQUEST:
          modal.error({
            content: t('modal.common.warnning.bad_request'),
            okText: t('modal.button.ok')
          });
          break;

        case API_RESPONSE_CODE.SERVICE_UNAVAILABLE:
          modal.error({
            content: t('modal.common.warnning.server_error'),
            okText: t('modal.button.ok')
          });
          break;

        default:
          modal.error({
            content: t('modal.common.warnning.unknown_error') + err.response.status,
            okText: t('modal.button.ok')
          });
          break;
      }

      setLoading(false);
    });
  }

  const onCancel_Modal = () => {
    setModalOpen(false);
  };

  const onChange_DeleteString = (e) => {
    setDeleteString(e.target.value);
  };

  return (
    <>
      <Tooltip title={t('project.building_info.menu.delete')}>
        <Button size='large' icon={<DeleteOutlined />} onClick={onClick_Modal}></Button>
      </Tooltip>

      <Modal closable={false} open={ModalOpen} confirmLoading={Loading} onOk={onOk_Modal} onCancel={onCancel_Modal} okText={t('modal.button.ok')} cancelText={t('modal.button.cancel')}
        okButtonProps={{ disabled: (DeleteString !== t('modal.building.delete.warnning_word')) }}
        title={
          <Space>
            <InfoCircleOutlined style={{ color: '#FF4D4F' }} />
            {t('modal.building.delete.title')}
          </Space>}>
        <Space className='space_center' direction='vertical'>
          {t('modal.building.delete.content')}
          {t('modal.building.delete.warnning_content')}
          <Input className='modal_input modal_icon_danger' value={DeleteString} maxLength={6} placeholder={t('modal.building.delete.warnning_word')} onChange={onChange_DeleteString} />
        </Space>
      </Modal >
    </>
  );
}