import { Result } from "antd";

export default function Maintenance() {
  return (
    <Result
      status="warning"
      title="홈페이지 리뉴얼 준비 중입니다."
      subTitle="오픈예정: 2024-06-27 ~"
      style={{ height: "80vh", paddingTop: "35vh" }} />
  );
}