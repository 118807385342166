import React, { useContext, useState } from 'react';
import { App, Button, Input, Space, Typography, Modal, Tag } from 'antd';
import { KeyOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { API_LIST, API_RESPONSE_CODE, ApiMain, METHOD } from '../../common/Api';
import { AppContext } from '../../App';

const { Text } = Typography;

export default function ModalUserPasswordUpdate() {
  const { t } = useTranslation();
  const { modal } = App.useApp();
  const { ID, accessToken, Refresh_API } = useContext(AppContext);

  const [ModalOpen, setModalOpen] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [Password, setPassword] = useState('');
  const [PasswordConfirm, setPasswordConfirm] = useState('');
  const [PasswordConfirmWarnning, setPasswordConfirmWarnning] = useState(false);

  const onClick_Modal = () => {
    setPasswordConfirmWarnning(false);
    setPassword('');
    setPasswordConfirm('');
    setModalOpen(true);
  };

  const onCancel_Modal = () => {
    setModalOpen(false);
  };

  const onOk_Modal = () => {
    if ((PasswordConfirm.length === 0) || (Password.length === 0) || (PasswordConfirm !== Password)) {
      setPasswordConfirmWarnning(true);
      return;
    }

    setLoading(true);
    ApiMain(API_LIST.USER_INFO_UPDATE, METHOD.POST, {
      id: ID,
      pw: Password
    }, accessToken).then((res) => {
      switch (res.status) {
        case API_RESPONSE_CODE.OK:
        case API_RESPONSE_CODE.ACCEPTED:
          modal.success({
            content: t('modal.change_passwd.complete'),
            okText: t('modal.button.ok'),
            onOk: () => {
              setModalOpen(false);
            }
          });
          break;

        case API_RESPONSE_CODE.NO_CONTENT:
          modal.warning({
            content: t('modal.change_passwd.warnning.no_content'),
            okText: t('modal.button.ok')
          });
          break;

        default:
          modal.warning({
            content: t('modal.common.warnning.unkown_response') + res.status,
            okText: t('modal.button.ok')
          });
          break;
      }

      setLoading(false);
    }).catch((err) => {
      switch (err.response.status) {
        case API_RESPONSE_CODE.PRECONDITION_FAILED:
          Refresh_API();
          break;
          
        case API_RESPONSE_CODE.UNAUTHORIZED:
          modal.error({
            content: t('modal.common.warnning.unauthorized'),
            okText: t('modal.button.ok')
          });
          break;

        case API_RESPONSE_CODE.NOT_ACCEPTABLE:
          modal.error({
            content: t('modal.common.warnning.not_acceptable'),
            okText: t('modal.button.ok')
          });
          break;

        case API_RESPONSE_CODE.BAD_REQUEST:
          modal.error({
            content: t('modal.common.warnning.bad_request'),
            okText: t('modal.button.ok')
          });
          break;

        case API_RESPONSE_CODE.SERVICE_UNAVAILABLE:
          modal.error({
            content: t('modal.common.warnning.server_error'),
            okText: t('modal.button.ok')
          });
          break;

        default:
          modal.error({
            content: t('modal.common.warnning.unknown_error') + err.response.status,
            okText: t('modal.button.ok')
          });
          break;
      }

      setLoading(false);
    });
  };

  const onChange_Password = (e) => {
    setPassword(e.target.value);
    setPasswordConfirmWarnning(false);
  };

  const onChange_PasswordConfirm = (e) => {
    setPasswordConfirm(e.target.value);
    setPasswordConfirmWarnning(false);
  };

  return (
    <>
      <Button className='header_menu_button' icon={<KeyOutlined />} onClick={onClick_Modal}>{t('main_menu.setting.change_passwd')}</Button>

      <Modal closable={false} open={ModalOpen} confirmLoading={Loading} onOk={onOk_Modal} onCancel={onCancel_Modal} okText={t('modal.button.ok')} cancelText={t('modal.button.cancel')}
        title={
          <Space>
            <KeyOutlined />
            {t('modal.change_passwd.title')}
          </Space>}>
        <Space direction='vertical space_center'>
          <Space>
            <Tag className='modal_subtitle' color='#F4BD40' style={{ color: '#222222' }}>{t('modal.change_passwd.subtitle.new_passwd')}</Tag>
            <Input.Password className='modal_input' value={Password} onChange={onChange_Password} />
          </Space>
          <Space className='modal_space_warnning'>
            <Tag className='modal_subtitle' color='#F4BD40' style={{ color: '#222222' }}>{t('modal.change_passwd.subtitle.confirm_passwd')}</Tag>
            <Space direction='vertical' size={0}>
              <Input.Password className='modal_input' value={PasswordConfirm} onChange={onChange_PasswordConfirm} />
              {PasswordConfirmWarnning ? <Text className='modal_error_text'>{t('modal.change_passwd.warnning.not_equal')}</Text> : null}
            </Space>
          </Space>
        </Space>
      </Modal>
    </>
  );
}