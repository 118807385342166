import React, { createContext, useContext, useEffect, useState } from 'react';
import { Select, Divider, Space, Button, Typography, Table, Checkbox, App } from 'antd';
import { DesignContext } from './Design';
import { API_LIST, API_RESPONSE_CODE, ApiMain, METHOD } from '../common/Api';
import ModalProfileEdit from '../components/profile/ModalProfileEdit';
import ModalProfileCreate from '../components/profile/ModalProfileCreate';
import { AppContext } from '../App';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;

export const ProfileContext = createContext();

export default function Profiles() {
  const { t } = useTranslation();
  const { ID, accessToken, Refresh_API } = useContext(AppContext);
  const { Zones, onClose_DrawerProfile } = useContext(DesignContext);
  const { modal } = App.useApp();

  const [ProfileList, setProfileList] = useState([]);
  const [ProfileTable, setProfileTable] = useState([]);
  const [SelectedProfile, setSelectedProfile] = useState();
  const [CheckedGroups, setCheckedGroups] = useState([]);
  const [CheckedAllGroup, setCheckedAllGroup] = useState(false);
  const [ProfileEditModalOpen, setProfileEditModalOpen] = useState(false);

  const onChange_Checkbox = (e, z_idx) => {
    let list = [...CheckedGroups];

    if (e.target.checked) {
      list.push(parseInt(z_idx));
    } else {
      for (let i = 0; i < list.length; i++) {
        if (list[i] === parseInt(z_idx)) {
          list.splice(i, 1);
          break;
        }
      }
    }

    if (list.length === ProfileTable.length) {
      setCheckedAllGroup(true);
    } else {
      setCheckedAllGroup(false);
    }

    setCheckedGroups(list);
  };

  const onChange_AllCheckbox = (e) => {
    let list = [];

    if (e.target.checked) {
      ProfileTable.forEach((v) => {
        list.push(v.z_idx);
      });
    }

    setCheckedGroups(list);
    setCheckedAllGroup(e.target.checked);
  };

  const onChange_SelectProfile = (value, options) => {
    setSelectedProfile({ pf_idx: options.key, pf_name: options.name });
  };

  const GetProfiles = (created_pf_idx) => {
    setProfileList([]);

    let i = 0;
    for (; i < Zones.length; i++) {
      if (Zones[i] != null) {
        break;
      }
    }

    ApiMain(API_LIST.PROFILE_LIST_GET, METHOD.POST, {
      id: ID,
      z_idx: Zones[i].z_idx
    }, accessToken).then((res) => {
      if (res.status === API_RESPONSE_CODE.OK) {
        setProfileList(res.data);

        for (const e of res.data) {
          if (created_pf_idx != null) {
            if (e.pf_idx === created_pf_idx) {
              setSelectedProfile({ pf_idx: e.pf_idx, pf_name: e.pf_name });
              setProfileEditModalOpen(true);
              break;
            }
          } else {
            if (e.type === 0) {
              setSelectedProfile({ pf_idx: e.pf_idx, pf_name: e.pf_name });
              break;
            }
          }
        }

        MakeProfileTable(res.data);
      }
    }).catch((err) => {
      console.log(err);

      if (err.response.status === API_RESPONSE_CODE.PRECONDITION_FAILED) {
        Refresh_API();
      }
    });
  };

  const MakeProfileTable = async (profile_list) => {
    let i = 0;
    for (; i < Zones.length; i++) {
      if (Zones[i] != null) {
        break;
      }
    }

    let z_pf_list = [];
    for (const pfl of profile_list) {
      const res = await ApiMain(API_LIST.PROFILE_APPLIED_GET, METHOD.POST, {
        id: ID,
        z_idx: Zones[i].z_idx,
        pf_idx: pfl.pf_idx
      }, accessToken);

      if (res.hasOwnProperty('status')) {
        if (res.status === API_RESPONSE_CODE.OK) {
          Zones.forEach((e1) => {
            if (e1 != null) {
              if (e1.z_idx !== -1) {
                res.data.forEach((e2) => {
                  if (e2 != null) {
                    if (e1.z_idx === e2.z_idx) {
                      z_pf_list.push({
                        key: e2.z_idx,
                        z_idx: e2.z_idx,
                        z_name: e2.z_name,
                        pf_name: '(' + pfl.pf_idx + ') ' + pfl.pf_name
                      });
                    }
                  }
                });
              }
            }
          });
        }
      }
    }

    Zones.forEach((e) => {
      if (e != null) {
        let exist = false;
        for (const p of z_pf_list) {
          if (e.z_idx === p.z_idx) {
            exist = true;
            break;
          }
        }
        if (!exist && (e.z_idx !== -1)) {
          z_pf_list.push({
            key: e.z_idx,
            z_idx: e.z_idx,
            z_name: e.z_name,
            pf_name: '없음'
          });
        }
      }
    });

    z_pf_list.sort((a, b) => a.z_idx - b.z_idx);

    setProfileTable(z_pf_list);
  };

  const onClick_ApplyProfile = async () => {
    if (CheckedGroups.length === 0) {
      modal.info({
        content: t('modal.profile.apply.warnning.no_selected_group'),
        okText: t('modal.button.ok'),
        cancelButtonProps: null
      })
      return;
    }
    if (SelectedProfile == null) {
      modal.info({
        content: t('modal.profile.apply.warnning.no_selected_profile'),
        okText: t('modal.button.ok'),
        cancelButtonProps: null
      })
      return;
    }

    let list = 0;
    for (const e of CheckedGroups) {
      let res = await ApiMain(API_LIST.PROFILE_UPDATE, METHOD.POST, {
        id: ID,
        z_idx: e,
        target_z_idx: e,
        pf_idx: SelectedProfile.pf_idx
      }, accessToken);

      if (res.hasOwnProperty('status')) {
        if (res.status === API_RESPONSE_CODE.ACCEPTED) {
          list++;
        }
      } else {
        console.log(res);
      }
    }

    modal.success({
      content: list + t('modal.profile.apply.complete'),
      okText: t('modal.button.ok'),
      cancelButtonProps: null,
      onOk: () => { GetProfiles(); }
    });
  };

  useEffect(() => {
    if (Zones.length > 1) {
      GetProfiles();
    }
  }, [Zones]);

  return (
    <div className='profile'>
      <ProfileContext.Provider value={{ SelectedProfile, GetProfiles, ProfileEditModalOpen, setProfileEditModalOpen }}>

        <Space direction='vertical'>
          <Space direction='vertical'>
            <Text style={{ fontSize: 25, fontWeight: 700 }}>{t('profile.title')}</Text>
            <Space direction='vertical' size={0}>
              <Text style={{ fontSize: 14, fontWeight: 500 }}>{t('profile.description')}</Text>
            </Space>

            <Divider className='divider' />

            <Space className='space_between'>
              <Select className='select' placeholder={t('profile.select.placeholder')} notFoundContent={t('profile.select.no_data')} value={(SelectedProfile != null) ? ('(' + SelectedProfile.pf_idx + ') ' + SelectedProfile.pf_name) : ''} onChange={onChange_SelectProfile}
                options={
                  ProfileList.map((pf) => ({
                    key: pf.pf_idx,
                    name: pf.pf_name,
                    value: '(' + pf.pf_idx + ') ' + pf.pf_name
                  }))}>
              </Select>
              <Space>
                <ModalProfileEdit />
                <ModalProfileCreate />
              </Space>
            </Space>

            <Table className='table' dataSource={ProfileTable} pagination={false} locale={{ emptyText: t('profile.table.no_data') }}
              columns={[{
                key: 'z_idx',
                dataIndex: 'z_idx',
                title: <Checkbox checked={CheckedAllGroup} onChange={onChange_AllCheckbox} />,
                render: (text) => <Checkbox checked={(() => {
                  for (const v of CheckedGroups) {
                    if (v === parseInt(text)) {
                      return true;
                    }
                  }
                  return false;
                })()} onChange={(e) => onChange_Checkbox(e, text)} />
              }, {
                key: 'z_name',
                dataIndex: 'z_name',
                title: <Text className='table_header'>{t('profile.table.header.group')}</Text>,
                render: (text) => <Space className='table_row'>{text}</Space>
              }, {
                key: 'pf_name',
                dataIndex: 'pf_name',
                title: <Text className='table_header'>{t('profile.table.header.applied_profile')}</Text>,
                render: (text) => <Space className='table_row'>{text}</Space>
              }]} />

            <Space className='inner_bottom space_between'>
              <Button type='primary' onClick={onClick_ApplyProfile}>{t('profile.button.apply_profile')}</Button>
              <Button type='primary' onClick={onClose_DrawerProfile}>{t('profile.button.close')}</Button>
            </Space>

          </Space>
        </Space>

      </ProfileContext.Provider>
    </div>
  );
}