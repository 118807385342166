import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { App, Modal, Input, Typography, Space, Button, Tooltip, Tag } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { API_LIST, API_RESPONSE_CODE, ApiMain, METHOD } from '../../common/Api';
import { AppContext } from '../../App';
import { ProjectContext } from '../../pages/Projects';

const { Text } = Typography;

export default function ModalProjectUpdate() {
  const { t } = useTranslation();
  const { modal } = App.useApp();
  const { ID, accessToken, Refresh_API } = useContext(AppContext);
  const { SelectedProject, onUpdate_Project } = useContext(ProjectContext);

  const [ModalOpen, setModalOpen] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [ProjectName, setProjectName] = useState('');
  const [ProjectNameWarnning, setProjectNameWarnning] = useState(false);

  const onClick_Modal = () => {
    setProjectNameWarnning(false);
    setProjectName((SelectedProject != null) ? SelectedProject.pj_name : '');
    setModalOpen(true);
  };

  const onCancel_Modal = () => {
    setModalOpen(false);
  };

  const onOk_Modal = () => {
    if (ProjectName.length === 0) {
      setProjectNameWarnning(true);
      return;
    }

    setLoading(true);
    ApiMain(API_LIST.PROJECT_UPDATE, METHOD.POST, {
      id: ID,
      pj_idx: SelectedProject.pj_idx,
      pj_name: ProjectName
    }, accessToken).then((res) => {
      switch (res.status) {
        case API_RESPONSE_CODE.ACCEPTED:
          modal.success({
            content: t('modal.project.update.complete'),
            okText: t('modal.button.ok'),
            onOk: () => {
              onUpdate_Project(res.data);
              setModalOpen(false);
            }
          });
          break;

        default:
          modal.warning({
            content: t('modal.common.warnning.unkown_response') + res.status,
            okText: t('modal.button.ok')
          });
          break;
      }

      setLoading(false);
    }).catch((err) => {
      switch (err.response.status) {
        case API_RESPONSE_CODE.PRECONDITION_FAILED:
          Refresh_API();
          break;
          
        case API_RESPONSE_CODE.UNAUTHORIZED:
          modal.error({
            content: t('modal.common.warnning.unauthorized'),
            okText: t('modal.button.ok')
          });
          break;

        case API_RESPONSE_CODE.NOT_ACCEPTABLE:
          modal.error({
            content: t('modal.common.warnning.not_acceptable'),
            okText: t('modal.button.ok')
          });
          break;

        case API_RESPONSE_CODE.BAD_REQUEST:
          modal.error({
            content: t('modal.common.warnning.bad_request'),
            okText: t('modal.button.ok')
          });
          break;

        case API_RESPONSE_CODE.SERVICE_UNAVAILABLE:
          modal.error({
            content: t('modal.common.warnning.server_error'),
            okText: t('modal.button.ok')
          });
          break;

        default:
          modal.error({
            content: t('modal.common.warnning.unknown_error') + err.response.status,
            okText: t('modal.button.ok')
          });
          break;
      }

      setLoading(false);
    });
  };

  const onChange_ProjectName = (e) => {
    setProjectName(e.target.value);
    setProjectNameWarnning(false);
  };

  return (
    <>
      <Tooltip title={t('project.project_info.menu.edit_info')}>
        <Button size='large' icon={<EditOutlined />} onClick={onClick_Modal}></Button>
      </Tooltip>

      <Modal closable={false} open={ModalOpen} confirmLoading={Loading} onOk={onOk_Modal} onCancel={onCancel_Modal} okText={t('modal.button.ok')} cancelText={t('modal.button.cancel')}
        title={
          <Space>
            <EditOutlined />
            {t('modal.project.update.title')}
          </Space>}>
        <Space direction='vertical space_center'>
          <Space className='modal_space_warnning'>
            <Tag className='modal_subtitle' color='#F4BD40' style={{ color: '#222222' }}>{t('modal.project.update.project_name')}</Tag>
            <Space direction='vertical' size={0}>
              <Input className='modal_input' value={ProjectName} onChange={onChange_ProjectName} />
              {ProjectNameWarnning ? <Text className='modal_error_text'>{t('modal.project.update.warnning.no_project_name')}</Text> : null}
            </Space>
          </Space>
        </Space>
      </Modal>
    </>
  );
}