import React, { useState, useEffect, useContext } from 'react';
import { Image, Space } from 'antd';
import { API_RESPONSE_CODE, ApiImage, METHOD } from '../../common/Api';
import { AppContext } from '../../App';

export default function FieldImage(props) {
  const { Refresh_API } = useContext(AppContext);

  const [Img, setImg] = useState('');

  const GetFieldImage = () => {
    if (props.img_url != null) {
      ApiImage(props.img_url, METHOD.GET).then((res) => {
        const originalImage = new File([res.data], 'imageName');
        const reader = new FileReader();
        reader.onload = (ev) => {
          const previewImage = String(ev.target?.result);
          setImg(previewImage);
        };
        reader.readAsDataURL(originalImage);
      }).catch((err) => {
        console.log(err);
        setImg('');

        if (err.response.status === API_RESPONSE_CODE.PRECONDITION_FAILED) {
          Refresh_API();
        }
      });
    }
  };

  useEffect(() => {
    GetFieldImage();
  }, [props]);

  return (
    <Space style={{ width: props.width, height: props.height, background: '#FFFFFF' }}>
      <Image src={Img} preview={props.preview} style={{ cursor: props.cursor }} onLoad={props.onLoad} />
    </Space>
  );
}