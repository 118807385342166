import React, { useContext, useState } from 'react';
import { Modal, Space, Typography, Tag, Tooltip } from 'antd';
import { MonitoringContext } from './Monitoring';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

const { Text } = Typography;

export default function ModalNode(props) {
  const ZONE_SELECT = {
    all: 'all'
  };
  const TIMEOUT_HOUR = 1;

  const { t } = useTranslation();
  const { selectedZone: SelectedZone } = useContext(MonitoringContext);
  const [ModalOpenNodeInfo, setModalOpenNodeInfo] = useState(false);

  const NODE_PROCESS = {
    NO_GROUP: 0,
    EMPTY_NODE: 1,
    ALLOCATED: 2,
    SET: 3
  };

  const onClick_Modal = () => {
    setModalOpenNodeInfo(true);
  };

  const onCancel_Modal = () => {
    setModalOpenNodeInfo(false);
  };

  const NodeTimeover = (timestamp) => {
    return (dayjs().diff(dayjs(timestamp), 'h', true) >= TIMEOUT_HOUR);
  };

  const NodeTextColor = (status) => {
    if (NodeTimeover(status.timestamp)) {
      return '#FFFFFF';
    } else if (status.level == null) {
      return '#FFFFFF';
    } else if (status.level > 0) {
      return '#222222';
    } else {
      return '#FFFFFF';
    }
  };

  const NodeBgColor = (status, process) => {
    if (NodeTimeover(status.timestamp)) {
      return '#777777';
    } else {
      if (process === NODE_PROCESS.SET) {
        if (status.level == null) {
          return '#777777';
        } else if (status.level > 0) {
          return '#F3BC40';
        } else {
          return '#777777';
        }
      } else if (process === NODE_PROCESS.ALLOCATED) {
        return '#088A29';
      } else if (process <= NODE_PROCESS.EMPTY_NODE) {
        return '#B40404';
      }
    }
  };

  const NodeStyle = (pn) => {
    const size = ((SelectedZone === pn.z_idx) || (SelectedZone === ZONE_SELECT.all)) ? 30 : 15;

    return {
      width: size,
      height: size,
      color: NodeTextColor(pn.light_status),
      border: '2px solid #AAAAAA',
      borderRadius: '50%',
      fontSize: size / 2,
      left: `calc(${pn.x}% - ${size / 2}px)`,
      top: `calc(${pn.y}% - ${size / 2}px)`,
      background: NodeBgColor(pn.light_status, pn.process),
      position: 'absolute',
      cursor: 'zoom-in'
    };
  };

  return (
    <>
      <Tooltip overlayInnerStyle={{ width: 300, padding: 10 }}
        title={
          <Space direction='vertical' size={1}>
            <Space>
              <Tag className='tag_node' color='#F4BD40' style={{ color: '#222222' }}>ID</Tag>
              <Text>{(props.pn.device_uuid != null) ? (props.pn.device_uuid.substring(0, 4) + '-' + props.pn.device_uuid.substring(34, 36)) : t('modal.monitoring.tag.no_id')}</Text>
            </Space>
            <Space>
              <Tag className='tag_node' color='#F4BD40' style={{ color: '#222222' }}>{t('modal.monitoring.tag.group')}</Tag>
              <Text>{(props.pn.z_name != null) ? props.pn.z_name : '그룹 없음'}</Text>
            </Space>
            <Space>
              <Tag className='tag_node' color='#F4BD40' style={{ color: '#222222' }}>{t('modal.monitoring.tag.lightness')}</Tag>
              <Text>{(props.pn.light_status.level != null) ? (props.pn.light_status.level + '%') : t('modal.monitoring.tag.no_data')}</Text>
            </Space>
            <Space>
              <Tag className='tag_node' color='#F4BD40' style={{ color: '#222222' }}>{t('modal.monitoring.tag.data_date')}</Tag>
              <Text>{(props.pn.light_status.timestamp != null) ? dayjs(props.pn.light_status.timestamp).format('YYYY-MM-DD HH:mm:ss') : t('modal.monitoring.tag.no_data')}</Text>
            </Space>
          </Space>}>

        <div className='space_center' style={NodeStyle(props.pn)} onClick={onClick_Modal}>
          {NodeTimeover(props.pn.light_status.timestamp) ? '-'
            : (props.pn.light_status.level != null) ? props.pn.light_status.level : ''}

          {/* {(props.iconMode == 'level') ?
            (props.pn.light_status.level == null) ? '' :

            // (dayjs().diff(dayjs(props.pn.light_status.timestamp), 'h', true) >= 1) ? <QuestionOutlined /> :
            (dayjs().diff(dayjs(props.pn.light_status.timestamp), 'h', true) >= 1) ? 'Off' :

              props.pn.light_status.level :
            (props.pn.process <= 2) ? <></> :
              (props.pn.light_status.level == null) ? <QuestionOutlined /> :

                // (dayjs().diff(dayjs(props.pn.light_status.timestamp), 'h', true) >= 1) ? <QuestionOutlined /> :
                (dayjs().diff(dayjs(props.pn.light_status.timestamp), 'h', true) >= 1) ? <BulbOutlined /> :

                  (props.pn.light_status.level == 0) ? <BulbOutlined /> : <BulbFilled />
          } */}
        </div>
      </Tooltip>

      {(props.pn.process > NODE_PROCESS.EMPTY_NODE) ?
        <Modal closable={false} open={ModalOpenNodeInfo} onCancel={onCancel_Modal} footer={null} centered={true}>
          <Space direction='vertical' size={1}>
            <Space>
              <Tag className='tag_node' color='#F4BD40' style={{ color: '#222222' }}>{t('modal.monitoring.tag.full_id')}</Tag>
              <Text>{props.pn.device_uuid}</Text>
            </Space>
            <Space>
              <Tag className='tag_node' color='#F4BD40' style={{ color: '#222222' }}>{t('modal.monitoring.tag.model_name')}</Tag>
              <Text>{props.pn.part_number}</Text>
            </Space>
            <Space>
              <Tag className='tag_node' color='#F4BD40' style={{ color: '#222222' }}>{t('modal.monitoring.tag.serial_number')}</Tag>
              <Text>{props.pn.serial_number}</Text>
            </Space>
            <Space>
              <Tag className='tag_node' color='#F4BD40' style={{ color: '#222222' }}>{t('modal.monitoring.tag.manufactured_date')}</Tag>
              <Text>{props.pn.manufactured_date.substring(0, 10)}</Text>
            </Space>
          </Space>
        </Modal> : null}
    </>
  );
}