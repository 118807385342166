import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Divider, Button, Modal, Row, Col, Spin } from 'antd';
import { AppContext } from '../../App';
import { API_LIST, API_RESPONSE_CODE, ApiMain, ApiPayment, METHOD } from '../../common/Api';

const { Text } = Typography;

export default function ModalPowerPayment(props) {
  const { t } = useTranslation();
  const { ID, accessToken, Refresh_API } = useContext(AppContext);

  const [ModalOpen, setModalOpen] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [SavePayment, setSavePayment] = useState(0);
  const [MaxPayment, setMaxPayment] = useState(0);
  const [TotalHour, setTotalHour] = useState([0, 0, 0]);
  const [TotalEnergy, setTotalEnergy] = useState([0, 0, 0]);
  const [Processing, setProcessing] = useState(-1);
  const [Cancel, setCancel] = useState(false);
  const [Nodes, setNodes] = useState([]);

  const onClick_Modal = () => {
    setSavePayment(0);
    setMaxPayment(0);
    setTotalHour([0, 0, 0]);
    setTotalEnergy([0, 0, 0]);
    setNodes([]);
    setCancel(false);

    setModalOpen(true);
    GetNodes();
  };

  const onCancel_ModalPowerPayment = () => {
    setCancel(true);
    setModalOpen(false);
  };

  const GetNodes = () => {
    setLoading(true);

    ApiMain(API_LIST.ZONE_BATCH_GET, METHOD.POST, {
      id: ID,
      f_idx: props.f_idx
    }, accessToken).then((res) => {
      switch (res.status) {
        case API_RESPONSE_CODE.OK:
          let node_list = [];

          res.data.forEach((e) => {
            e.pn.forEach((_e) => {
              _e.z_name = e.z_name;
              node_list.push(_e);
            });
          });
          setNodes(node_list);
          setProcessing(0);
          break;

        default:
          console.log(res);
          break;
      }
    }).catch((err) => {
      console.log(err);
      setLoading(false);

      if (err.response.status === API_RESPONSE_CODE.PRECONDITION_FAILED) {
        Refresh_API();
      }
    });
  };

  const GetPowerPayment = async (device_uuid) => {
    return await ApiPayment(API_LIST.POWER_PAYMENT_GET, METHOD.POST, {
      device_uuid: device_uuid,
      year: props.year.toString(),
      month: props.month.toString()
    }, accessToken);
  };

  const ProcessingUpdate = async () => {
    if ((Nodes.length > 0) && (Processing < Nodes.length)) {
      let save_payment = SavePayment;
      let max_payment = MaxPayment;
      let total_cal_hour = [...TotalHour];
      let total_cal_watt = [...TotalEnergy];
      let proc = Processing + 1;

      if (Nodes[Processing].device_uuid != null) {
        let _res = await GetPowerPayment(Nodes[Processing].device_uuid);
        if (_res.status === API_RESPONSE_CODE.OK) {
          save_payment += _res.data.save_payment;
          max_payment += _res.data.max_payment;

          for (let j = 0; j < 3; j++) {
            total_cal_hour[j] += _res.data.total_cal_hour[j];
            total_cal_watt[j] += _res.data.total_cal_watt[j];
          }

          setSavePayment(save_payment);
          setMaxPayment(max_payment);
          setTotalHour(total_cal_hour);
          setTotalEnergy(total_cal_watt);
        }
      }

      if ((Processing + 1) >= Nodes.length) {
        setLoading(false);
      }

      setProcessing(proc);
    }
  };

  useEffect(() => {
    if (!Cancel) {
      ProcessingUpdate();
    }
  }, [Processing]);

  return (
    <>
      <Button type='primary' onClick={onClick_Modal}>요금 계산</Button>

      <Modal closable={false} open={ModalOpen} confirmLoading={Loading}
        okText={t('modal.button.ok')} onOk={onCancel_ModalPowerPayment}
        cancelText={t('modal.button.cancel')} cancelButtonProps={{ onClick: onCancel_ModalPowerPayment }}
        width={800} title='요금 계산'>

        <Spin spinning={Loading} size='large' tip={"계산 중입니다. (" + Nodes.length + "대 중 " + Processing + "대 완료)"} style={{ fontSize: 20, fontWeight: 500, color: "#CCCCCC" }}>
          <Text style={{ fontSize: 16, fontWeight: 500 }}>조명 전기요금 ({props.year}년 {props.month + 1}월)</Text>
          <Row gutter={[16, 16]} align='middle' style={{ border: '1px solid #FFFFFF', marginTop: 5, marginBottom: 20 }}>
            <Col span={12} style={{ display: 'flex', justifyContent: 'center', borderBottom: '0.5px solid #AAAAAA' }}><div>사용요금 (추정값)</div></Col>
            <Col span={12} style={{ display: 'flex', justifyContent: 'center', borderBottom: '0.5px solid #AAAAAA' }}><div>최대(150Wh)로 사용했을 경우</div></Col>

            <Col span={12} style={{ display: 'flex', justifyContent: 'center' }}><div>{Math.floor(SavePayment).toLocaleString()} 원</div></Col>
            <Col span={12} style={{ display: 'flex', justifyContent: 'center' }}><div>{Math.floor(MaxPayment).toLocaleString()} 원</div></Col>
          </Row>

          <Text style={{ fontSize: 16, fontWeight: 500 }}>부하별 사용 전력량</Text>
          <Row gutter={[16, 16]} align='middle' style={{ border: '1px solid #FFFFFF', marginTop: 5, marginBottom: 20 }}>
            <Col span={6} style={{ display: 'flex', justifyContent: 'center', borderBottom: '0.5px solid #AAAAAA' }}><div>경부하</div></Col>
            <Col span={6} style={{ display: 'flex', justifyContent: 'center', borderBottom: '0.5px solid #AAAAAA' }}><div>중간부하</div></Col>
            <Col span={6} style={{ display: 'flex', justifyContent: 'center', borderBottom: '0.5px solid #AAAAAA' }}><div>최대부하</div></Col>
            <Col span={6} style={{ display: 'flex', justifyContent: 'center', borderBottom: '0.5px solid #AAAAAA' }}><div>계</div></Col>

            <Col span={6} style={{ display: 'flex', justifyContent: 'center' }}><div>{(TotalEnergy[0] / 1000000).toLocaleString()} kWh</div></Col>
            <Col span={6} style={{ display: 'flex', justifyContent: 'center' }}><div>{(TotalEnergy[1] / 1000000).toLocaleString()} kWh</div></Col>
            <Col span={6} style={{ display: 'flex', justifyContent: 'center' }}><div>{(TotalEnergy[2] / 1000000).toLocaleString()} kWh</div></Col>
            <Col span={6} style={{ display: 'flex', justifyContent: 'center' }}><div>{((TotalEnergy[0] + TotalEnergy[1] + TotalEnergy[2]) / 1000000).toLocaleString()} kWh</div></Col>
          </Row>

          <Text style={{ fontSize: 16, fontWeight: 500 }}>부하별 가동시간 (모든 조명 가동시간 합산)</Text>
          <Row gutter={[16, 16]} align='middle' style={{ border: '1px solid #FFFFFF', marginTop: 5, marginBottom: 20 }}>
            <Col span={6} style={{ display: 'flex', justifyContent: 'center', borderBottom: '0.5px solid #AAAAAA' }}><div>경부하</div></Col>
            <Col span={6} style={{ display: 'flex', justifyContent: 'center', borderBottom: '0.5px solid #AAAAAA' }}><div>중간부하</div></Col>
            <Col span={6} style={{ display: 'flex', justifyContent: 'center', borderBottom: '0.5px solid #AAAAAA' }}><div>최대부하</div></Col>
            <Col span={6} style={{ display: 'flex', justifyContent: 'center', borderBottom: '0.5px solid #AAAAAA' }}><div>계</div></Col>

            <Col span={6} style={{ display: 'flex', justifyContent: 'center' }}><div>{TotalHour[0].toLocaleString()} 시간</div></Col>
            <Col span={6} style={{ display: 'flex', justifyContent: 'center' }}><div>{TotalHour[1].toLocaleString()} 시간</div></Col>
            <Col span={6} style={{ display: 'flex', justifyContent: 'center' }}><div>{TotalHour[2].toLocaleString()} 시간</div></Col>
            <Col span={6} style={{ display: 'flex', justifyContent: 'center' }}><div>{(TotalHour[0] + TotalHour[1] + TotalHour[2]).toLocaleString()} 시간<br />(평균 {((TotalHour[0] + TotalHour[1] + TotalHour[2]) / 62 / 24).toLocaleString()} 일)</div></Col>
          </Row>
        </Spin>

        <Divider />

        <Text style={{ fontSize: 16, fontWeight: 500 }}>계절별 시간대별 구분</Text>
        <Row gutter={[16, 16]} align='middle' style={{ border: '1px solid #FFFFFF', marginTop: 5, marginBottom: 20 }}>
          <Col span={6} style={{ display: 'flex', justifyContent: 'center', borderBottom: '0.5px solid #AAAAAA' }}><div>구분</div></Col>
          <Col span={6} style={{ display: 'flex', justifyContent: 'center', borderBottom: '0.5px solid #AAAAAA' }}><div>여름철 (6~8월)</div></Col>
          <Col span={6} style={{ display: 'flex', justifyContent: 'center', borderBottom: '0.5px solid #AAAAAA' }}><div>봄가을철 (3~5월, 9~10월)</div></Col>
          <Col span={6} style={{ display: 'flex', justifyContent: 'center', borderBottom: '0.5px solid #AAAAAA' }}><div>겨울철 (11~2월)</div></Col>

          <Col span={6} style={{ display: 'flex', justifyContent: 'center' }}><div>경부하</div></Col>
          <Col span={6} style={{ display: 'flex', justifyContent: 'center' }}><div>22:00 ~ 08:00</div></Col>
          <Col span={6} style={{ display: 'flex', justifyContent: 'center' }}><div>22:00 ~ 08:00</div></Col>
          <Col span={6} style={{ display: 'flex', justifyContent: 'center' }}><div>22:00 ~ 08:00</div></Col>

          <Col span={6} style={{ display: 'flex', justifyContent: 'center' }}><div>중간부하</div></Col>
          <Col span={6} style={{ display: 'flex', justifyContent: 'center' }}><div>08:00 ~ 11:00<br />12:00 ~ 13:00<br />18:00 ~ 22:00</div></Col>
          <Col span={6} style={{ display: 'flex', justifyContent: 'center' }}><div>08:00 ~ 11:00<br />12:00 ~ 13:00<br />18:00 ~ 22:00</div></Col>
          <Col span={6} style={{ display: 'flex', justifyContent: 'center' }}><div>08:00 ~ 09:00<br />12:00 ~ 16:00<br />19:00 ~ 22:00</div></Col>

          <Col span={6} style={{ display: 'flex', justifyContent: 'center' }}><div>최대부하</div></Col>
          <Col span={6} style={{ display: 'flex', justifyContent: 'center' }}><div>11:00 ~ 12:00<br />13:00 ~ 18:00</div></Col>
          <Col span={6} style={{ display: 'flex', justifyContent: 'center' }}><div>11:00 ~ 12:00<br />13:00 ~ 18:00</div></Col>
          <Col span={6} style={{ display: 'flex', justifyContent: 'center' }}><div>09:00 ~ 12:00<br />16:00 ~ 19:00</div></Col>
        </Row>

        <Text style={{ fontSize: 16, fontWeight: 500 }}>전력량요금 단가 (산업용 을 고압A)</Text>
        <Row gutter={[16, 16]} align='middle' style={{ border: '1px solid #FFFFFF', marginTop: 5 }}>
          <Col span={6} style={{ display: 'flex', justifyContent: 'center', borderBottom: '0.5px solid #AAAAAA' }}><div>구분</div></Col>
          <Col span={6} style={{ display: 'flex', justifyContent: 'center', borderBottom: '0.5px solid #AAAAAA' }}><div>여름철 (6~8월)</div></Col>
          <Col span={6} style={{ display: 'flex', justifyContent: 'center', borderBottom: '0.5px solid #AAAAAA' }}><div>봄가을철 (3~5월, 9~10월)</div></Col>
          <Col span={6} style={{ display: 'flex', justifyContent: 'center', borderBottom: '0.5px solid #AAAAAA' }}><div>겨울철 (11~2월)</div></Col>

          <Col span={6} style={{ display: 'flex', justifyContent: 'center' }}><div>경부하</div></Col>
          <Col span={6} style={{ display: 'flex', justifyContent: 'center' }}><div>87.3 원</div></Col>
          <Col span={6} style={{ display: 'flex', justifyContent: 'center' }}><div>87.3 원</div></Col>
          <Col span={6} style={{ display: 'flex', justifyContent: 'center' }}><div>94.3 원</div></Col>

          <Col span={6} style={{ display: 'flex', justifyContent: 'center' }}><div>중간부하</div></Col>
          <Col span={6} style={{ display: 'flex', justifyContent: 'center' }}><div>140.2 원</div></Col>
          <Col span={6} style={{ display: 'flex', justifyContent: 'center' }}><div>109.8 원</div></Col>
          <Col span={6} style={{ display: 'flex', justifyContent: 'center' }}><div>140.4 원</div></Col>

          <Col span={6} style={{ display: 'flex', justifyContent: 'center' }}><div>최대부하</div></Col>
          <Col span={6} style={{ display: 'flex', justifyContent: 'center' }}><div>222.3 원</div></Col>
          <Col span={6} style={{ display: 'flex', justifyContent: 'center' }}><div>140.5 원</div></Col>
          <Col span={6} style={{ display: 'flex', justifyContent: 'center' }}><div>197.9 원</div></Col>
        </Row>
      </Modal>
    </>
  );
}