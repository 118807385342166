import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Input, Upload, Typography, Space, Tag, App, Button } from 'antd';
import { EditOutlined, CloudUploadOutlined } from '@ant-design/icons';
import { ProjectContext } from '../../pages/Projects';
import { API_LIST, API_RESPONSE_CODE, ApiImage, ApiMain, METHOD } from '../../common/Api';
import { AppContext } from '../../App';

const { Dragger } = Upload;
const { Text } = Typography;

export default function ModalFieldUpdate(props) {
  const { t } = useTranslation();
  const { modal } = App.useApp();
  const { ID, accessToken, Refresh_API } = useContext(AppContext);

  const [ModalOpen, setModalOpen] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [FieldName, setFieldName] = useState('');
  const [FieldNameWarnning, setFieldNameWarnning] = useState(false);
  const [FieldImageWarnning, setFieldImageWarnning] = useState(false);
  const [FieldImageList, setFieldImageList] = useState([]);

  const onClick_Modal = () => {
    setFieldNameWarnning(false);
    setFieldImageWarnning(false);
    setFieldName(props.f.f_name);
    setFieldImageList([]);
    setModalOpen(true);
  };

  const onCancel_Modal = () => {
    setModalOpen(false);
  };

  const UploadImage = (f_idx) => {
    const formData = new FormData();
    formData.append('image', FieldImageList[0].originFileObj);
    ApiImage('floor_img/' + f_idx, METHOD.POST, formData).then((res) => {
      switch (res.status) {
        case API_RESPONSE_CODE.ACCEPTED:
          window.location.reload();
          break;

        default:
          console.log(res);
          break;
      }

      setModalOpen(false);
    }).catch((err) => {
      console.log(err);

      setModalOpen(false);

      if (err.response.status === API_RESPONSE_CODE.PRECONDITION_FAILED) {
        Refresh_API();
      }
    });
  };

  const UpdateField = () => {
    setLoading(true);
    ApiMain(API_LIST.FIELD_UPDATE, METHOD.POST, {
      id: ID,
      f_idx: props.f.f_idx,
      f_name: FieldName
    }, accessToken).then((res) => {
      switch (res.status) {
        case API_RESPONSE_CODE.ACCEPTED:
          modal.success({
            content: t('modal.field.update.complete'),
            okText: t('modal.button.ok'),
            onOk: () => {
              setModalOpen(false);
              window.location.reload();
            }
          });
          break;

        default:
          modal.warning({
            content: t('modal.common.warnning.unkown_response') + res.status,
            okText: t('modal.button.ok')
          });
          break;
      }

      setLoading(false);
    }).catch((err) => {
      switch (err.response.status) {
        case API_RESPONSE_CODE.PRECONDITION_FAILED:
          Refresh_API();
          break;
          
        case API_RESPONSE_CODE.UNAUTHORIZED:
          modal.error({
            content: t('modal.common.warnning.unauthorized'),
            okText: t('modal.button.ok')
          });
          break;

        case API_RESPONSE_CODE.NOT_ACCEPTABLE:
          modal.error({
            content: t('modal.common.warnning.not_acceptable'),
            okText: t('modal.button.ok')
          });
          break;

        case API_RESPONSE_CODE.BAD_REQUEST:
          modal.error({
            content: t('modal.common.warnning.bad_request'),
            okText: t('modal.button.ok')
          });
          break;

        case API_RESPONSE_CODE.SERVICE_UNAVAILABLE:
          modal.error({
            content: t('modal.common.warnning.server_error'),
            okText: t('modal.button.ok')
          });
          break;

        default:
          modal.error({
            content: t('modal.common.warnning.unknown_error') + err.response.status,
            okText: t('modal.button.ok')
          });
          break;
      }

      setLoading(false);
    });
  };

  const onOk_Modal = (values) => {
    if ((FieldName.length === 0) && (FieldImageList.length === 0)) {
      setFieldNameWarnning(null);
      setFieldImageWarnning(null);
      return;
    }

    if (props.f.f_name !== FieldName) {
      UpdateField();
    } else if (FieldImageList.length > 0) {
      UploadImage(props.f.f_idx);
    } else {
      setModalOpen(false);
    }
  };

  const onChange_FieldName = (e) => {
    setFieldName(e.target.value);
    setFieldNameWarnning(false);
  };

  const onChange_ImageUpload = (e) => {
    setFieldImageList(e.fileList);

    if (e.file.status === 'error') {
      e.file.response = '';
      e.file.status = 'success';
      e.file.percent = 100;
      setFieldImageWarnning(false);
    } else if (e.file.status === 'removed') {
      setFieldImageList([]);
    }
  }

  return (
    <>
      <Button className='divider_title' type='primary' icon={<EditOutlined />} block onClick={onClick_Modal}>{t('project.field_info.menu.edit_info')}</Button>

      <Modal closable={false} open={ModalOpen} confirmLoading={Loading} onOk={onOk_Modal} onCancel={onCancel_Modal} okText={t('modal.button.ok')} cancelText={t('modal.button.cancel')}
        title={
          <Space>
            <EditOutlined />
            {t('modal.field.update.title')}
          </Space>}>
        <Space direction='vertical space_center'>
          <Space className='modal_space_warnning'>
            <Tag className='modal_subtitle' color='#F4BD40' style={{ color: '#222222' }}>{t('modal.field.update.field_name')}</Tag>
            <Space direction='vertical' size={0}>
              <Input className='modal_input' value={FieldName} onChange={onChange_FieldName} />
              {FieldNameWarnning ? <Text className='modal_error_text'>{t('modal.field.update.warnning.no_field_name')}</Text> : null}
            </Space>
          </Space>

          <Dragger maxCount={1} accept='.png,.jpg' style={{ width: 460 }} onChange={onChange_ImageUpload} fileList={FieldImageList}>
            <Space direction='vertical'>
              <CloudUploadOutlined style={{ fontSize: 40 }} />
              <Text>{t('modal.field.update.image_upload_subtitle')}</Text>
            </Space>
          </Dragger>
          {FieldImageWarnning ? <Text className='modal_error_text'>{t('modal.field.update.warnning.no_image')}</Text> : null}
        </Space>
      </Modal>
    </>
  );
}