import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Select, Space, Radio, Typography, Tag, Card, DatePicker, ConfigProvider } from 'antd';
import { ProjectContext } from '../../pages/Projects';
import { API_LIST, API_RESPONSE_CODE, ApiMain, METHOD } from '../../common/Api';
import { AppContext } from '../../App';
import ModalPowerPayment from './ModalPowerPayment';
import { Column } from '@ant-design/charts';
import dayjs from 'dayjs';
import locale from 'antd/locale/ko_KR';
import 'dayjs/locale/ko';
dayjs.locale('ko');

const { Text } = Typography;

export default function EnergyChart() {
  const SHOW_TYPE = {
    day: 'monthly',
    month: 'yearly'
  };
  const FIELD_SELECT = {
    all: 'all'
  };

  const { t, i18n } = useTranslation();
  const { ID, accessToken, Refresh_API } = useContext(AppContext);
  const { Fields, SelectedBuilding, RunningCount } = useContext(ProjectContext);

  const [InnerWidth, setInnerWidth] = useState(window.innerWidth);
  const [SelectedField, setSelectedField] = useState(FIELD_SELECT.all);
  const [ShowType, setShowType] = useState(SHOW_TYPE.day);
  const [SelectDate, setSelectDate] = useState(dayjs(new Date()));
  const [ColumnData, setChartData] = useState([]);
  const [SumEnergy, setSumEnergy] = useState(0);
  const [CalendarLocale, setCalendarLocale] = useState(locale);

  const ConvertDateMonth = (value) => {
    switch (value) {
      case 1:
        return t('date.month.jan');
      case 2:
        return t('date.month.feb');
      case 3:
        return t('date.month.mar');
      case 4:
        return t('date.month.apr');
      case 5:
        return t('date.month.may');
      case 6:
        return t('date.month.jun');
      case 7:
        return t('date.month.jul');
      case 8:
        return t('date.month.aug');
      case 9:
        return t('date.month.sep');
      case 10:
        return t('date.month.oct');
      case 11:
        return t('date.month.nov');
      case 12:
        return t('date.month.dec');
    }
  };

  const ConvertDateDay = (value) => {
    switch (value) {
      case 1:
        return t('date.day.' + value + 'st');
      case 2:
        return t('date.day.' + value + 'nd');
      case 3:
        return t('date.day.' + value + 'rd');
      default:
        return t('date.day.' + value + 'th');
    }
  };

  const onChange_ShowType = (e) => {
    setShowType(e.target.value);
    GetChartDataEntry(SelectedField, e.target.value, (e.target.value === SHOW_TYPE.day) ? SelectDate.format('YYYY-MM') : SelectDate.format('YYYY'));
  };

  const onChange_SelectDate = (date, dateString) => {
    setSelectDate(date);
    GetChartDataEntry(FIELD_SELECT.all, ShowType, dateString)
  };

  const onChange_SelectField = (value) => {
    setSelectedField(value);
    GetChartDataEntry(value, ShowType, (ShowType === SHOW_TYPE.day) ? SelectDate.format('YYYY-MM') : SelectDate.format('YYYY'));
  };

  const GetChartDataEntry = (value, show_type, date) => {
    if (value === FIELD_SELECT.all) {
      if (Fields.length !== 0) {
        GetEnergyChartData(show_type, date, Fields.map((value) => value.f_idx));
      }
    } else {
      GetEnergyChartData(show_type, date, [value]);
    }
  };

  const GetEnergyChartData = (type, base_date, field) => {
    if (isNaN(new Date(base_date))) {
      return;
    }

    ApiMain(API_LIST.POWER_STATISTICS_GET, METHOD.POST, {
      id: ID,
      f_idx_arr: field,
      type: ((type === 'monthly') ? 2 : 1),
      base_date: base_date
    }, accessToken).then((res) => {
      if (type === 'monthly') {
        let data = [];
        let date_index = new Date(base_date);
        date_index.setDate(1);

        let sum_energy = 0;
        let this_month = date_index.getMonth();
        for (let i = this_month; i === date_index.getMonth(); date_index.setDate(date_index.getDate() + 1)) {
          let value = 0;
          for (const e of res.data) {
            if (date_index.getDate() === (new Date(e.base_date_month).getDate())) {
              value = e.energy_sum / 1000;
              sum_energy += value;
              break;
            }
          }
          data.push({
            date: ConvertDateDay(date_index.getDate()),
            value: value
          });
        }
        setChartData(data);
        setSumEnergy(sum_energy);
      } else if (type === SHOW_TYPE.month) {
        let data = [];
        let date_index = new Date(base_date);
        date_index.setMonth(0);

        let sum_energy = 0;
        for (let i = 0; i < 12; i++) {
          let value = 0;
          for (const e of res.data) {
            if (i === (new Date(e.base_date_year).getMonth())) {
              value = e.energy_sum / 1000;
              sum_energy += value;
              break;
            }
          }
          data.push({
            date: ConvertDateMonth(i + 1),
            value: value
          });
        }
        setChartData(data);
        setSumEnergy(sum_energy);
      }
    }).catch((err) => {
      console.log(err);
      setChartData('');

      if (err.response.status === API_RESPONSE_CODE.PRECONDITION_FAILED) {
        Refresh_API();
      }
    });
  };

  const innerWidthResizeListener = () => {
    setInnerWidth(window.innerWidth);
  };

  useEffect(() => {
    const NOW = dayjs(new Date());
    setSelectDate(NOW);
    GetChartDataEntry(SelectedField, ShowType, (ShowType === SHOW_TYPE.day) ? NOW.format('YYYY-MM') : NOW.format('YYYY'));
    setCalendarLocale((i18n.language === 'en') ? null : locale);
  }, [i18n.language]);

  useEffect(() => {
    GetChartDataEntry(SelectedField, ShowType, (ShowType === SHOW_TYPE.day) ? SelectDate.format('YYYY-MM') : SelectDate.format('YYYY'));
  }, [Fields]);

  useEffect(() => {
    setSelectDate(dayjs(new Date()));
    setSelectedField(FIELD_SELECT.all);
  }, [SelectedBuilding]);

  useEffect(() => {
    window.addEventListener('resize', innerWidthResizeListener);
    return (() => {
      window.removeEventListener('resize', innerWidthResizeListener);
    });
  }, []);

  return (
    <ConfigProvider locale={CalendarLocale}>

      <Card title={<Text className='card_title'>{t('energy.field.title')}</Text>}
        extra={
          (SelectedField != null) ? (
            ((SelectedField === 139) || (SelectedField === 154) || (SelectedField === 155) || (SelectedField === 156) || (SelectedField === 239) || (SelectedField === 190)) ?
              <ModalPowerPayment year={SelectDate.format('YYYY')} month={SelectDate.format('MM')} f_idx={SelectedField} />
              : null) : null}>
        <Space className='space_center' direction='vertical' size={30}>
          <Space direction='vertical'>
            <Space>
              <Tag className='space_center tag_subtitle' color='#F4BD40'>{t('energy.field.select_field')}</Tag>
              <Select className='select_sub' value={SelectedField} onChange={onChange_SelectField}
                options={[{
                  key: -1,
                  value: FIELD_SELECT.all,
                  label: t('energy.field.radio_button.all_field')
                },
                ...Fields.map((value, index) => ({
                  key: index,
                  value: value.f_idx,
                  label: '(' + value.f_idx + ') ' + value.f_name
                }))]} />
            </Space>
            <Space>
              <Tag className='space_center tag_subtitle' color='#F4BD40'>{t('energy.field.running_lights')}</Tag>
              <Tag className='space_center tag_subcontent'>
                {(SelectedField === FIELD_SELECT.all) ?
                  RunningCount.reduce((sum, cur) => (sum + cur), 0)
                  : (() => {
                    for (let i = 0; i < Fields.length; i++) {
                      if (SelectedField === Fields[i].f_idx) {
                        return RunningCount[i];
                      }
                    }
                    return 0;
                  })()}
              </Tag>
            </Space>
          </Space>

          <Space className='space_center' direction='vertical' size={0}>
            <Space className='space_center'>
              <Radio.Group size='large' buttonStyle='solid' value={ShowType} onChange={onChange_ShowType}>
                <Radio.Button className='radio' value={SHOW_TYPE.day}>{t('energy.field.radio_button.monthly')}</Radio.Button>
                <Radio.Button className='radio' value={SHOW_TYPE.month}>{t('energy.field.radio_button.yearly')}</Radio.Button>
              </Radio.Group>
              <DatePicker size='large' value={SelectDate} picker={(ShowType === SHOW_TYPE.day) ? 'month' : 'year'} allowClear={false} onChange={onChange_SelectDate} />
            </Space>

            <Space style={{ width: InnerWidth - 150, justifyContent: 'end', alignItems: 'end', display: 'flex', direction: 'row' }} size={0}>
              <Space className='energy_sum_left'>{t('energy.field.total_consumption')}</Space>
              <Space className='energy_sum_right'>{SumEnergy.toLocaleString() + ' kWh'}</Space>
            </Space>

            <Column width={InnerWidth - 100} height={400} data={ColumnData} xField='date' yField='value' legend={false}
              label={{ text: (data) => parseInt(data.value).toLocaleString(), textBaseline: 'bottom', fill: '#FFFFFF' }}
              axis={{ x: { labelFill: '#FFFFFF', labelFontSize: 15 }, y: { title: 'kWh', titleFontSize: 25, titleFontWeight: 700, titleFill: '#FFFFFF', labelFill: '#FFFFFF', labelFontSize: 15 } }}
              tooltip={(d) => ({ name: t('energy.field.radio_button.usage'), value: d.value.toLocaleString() + ' kWh' })}
              style={{ radiusTopLeft: 10, radiusTopRight: 10, fill: '#F4BD40', maxWidth: (ShowType === SHOW_TYPE.day) ? 15 : 30 }} />
          </Space>
        </Space>
      </Card>

    </ConfigProvider>
  );
}