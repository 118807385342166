import { useContext, useState } from 'react';
import { Modal, Button, Space, Tooltip, App } from 'antd';
import { DeleteOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { DesignContext } from '../../pages/Design';
import { API_LIST, API_RESPONSE_CODE, ApiMain, METHOD } from '../../common/Api';
import { AppContext } from '../../App';
import { useTranslation } from 'react-i18next';

export default function ModalZoneDelete(props) {
  const { t } = useTranslation();
  const { ID, accessToken, Refresh_API } = useContext(AppContext);
  const { GetNodes } = useContext(DesignContext);
  const { modal } = App.useApp();

  const [ModalOpen, setModalOpen] = useState(false);
  const [Loading, setLoading] = useState(false);

  const onClick_Modal = () => {
    setModalOpen(true);
  };

  const onCancel_Modal = () => {
    setModalOpen(false);
  };

  const onOk_ModalRoleDelete = () => {
    setLoading(true);
    ApiMain(API_LIST.ZONE_DELETE, METHOD.POST, {
      id: ID,
      z_idx: props.z_key,
    }, accessToken).then((res) => {
      switch (res.status) {
        case API_RESPONSE_CODE.ACCEPTED:
          GetNodes();
          setModalOpen(false);
          break;

        default:
          modal.warning({
            content: t('modal.common.warnning.unkown_response') + res.status,
            okText: t('modal.button.ok')
          });
          break;
      }

      setLoading(false);
    }).catch((err) => {
      switch (err.response.status) {
        case API_RESPONSE_CODE.PRECONDITION_FAILED:
          Refresh_API();
          break;
        
        case API_RESPONSE_CODE.FORBIDDEN:
          modal.error({
            content: t('modal.zone.delete.warnning.forbidden'),
            okText: t('modal.button.ok')
          });
          break;

        case API_RESPONSE_CODE.UNAUTHORIZED:
          modal.error({
            content: t('modal.common.warnning.unauthorized'),
            okText: t('modal.button.ok')
          });
          break;

        case API_RESPONSE_CODE.NOT_ACCEPTABLE:
          modal.error({
            content: t('modal.common.warnning.not_acceptable'),
            okText: t('modal.button.ok')
          });
          break;

        case API_RESPONSE_CODE.BAD_REQUEST:
          modal.error({
            content: t('modal.common.warnning.bad_request'),
            okText: t('modal.button.ok')
          });
          break;

        case API_RESPONSE_CODE.SERVICE_UNAVAILABLE:
          modal.error({
            content: t('modal.common.warnning.server_error'),
            okText: t('modal.button.ok')
          });
          break;

        default:
          modal.error({
            content: t('modal.common.warnning.unknown_error') + err.response.status,
            okText: t('modal.button.ok')
          });
          break;
      }

      setLoading(false);
    });
  };

  return (
    <>
      <Tooltip title={t('modal.zone.delete.title')}>
        <Button icon={<DeleteOutlined />} onClick={onClick_Modal} />
      </Tooltip>

      <Modal closable={false} open={ModalOpen} confirmLoading={Loading} onOk={onOk_ModalRoleDelete} onCancel={onCancel_Modal} okText={t('modal.button.ok')} cancelText={t('modal.button.cancel')}
        title={
          <Space>
            <InfoCircleOutlined style={{ color: '#FF4D4F' }} />
            {t('modal.zone.delete.title')}
          </Space>}>
        {t('modal.zone.delete.content')}
      </Modal>
    </>
  );
}