import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, Input, Card, Space, Image, App } from 'antd';
import { LockOutlined, HomeOutlined, TagOutlined, MailOutlined } from '@ant-design/icons';
import { API_LIST, API_RESPONSE_CODE, ApiMain, METHOD } from '../common/Api';
import { AppContext } from '../App';
import { BRAND_URL } from '../common/Brand';

export default function Join() {
  const { t } = useTranslation();
  const { ID, setId: setID, accessToken, setAccessToken, setRefreshToken, Refresh_API, ROUTE_URL_LIST, ROUTE_URL_INDEX } = useContext(AppContext);
  const navigate = useNavigate();
  const { modal } = App.useApp();
  
  const [Pw, setPw] = useState('');
  const [PwConfirm, setPwConfirm] = useState('');
  const [Company, setCompany] = useState('');
  const [Name, setName] = useState('');
  const [Email, setEmail] = useState('');

  const onClick_SignUp = () => {
    if (Email.length === 0) {
      modal.error({
        content: t('modal.join.warnning.bad_request'),
        okText: t('modal.button.ok'),
        onOk: () => {
          for (const e of BRAND_URL) {
            if (window.location.pathname.includes(e.url)) {
              navigate(e.url + '/', { replace: true });
              return;
            }
          }
        }
      });
      return;
    }
    if ((Pw.length === 0) || (PwConfirm.length === 0) || (Company.length === 0) || (Name.length === 0)) {
      modal.warning({
        content: t('modal.join.warnning.input_information'),
        okText: t('modal.button.ok')
      });
      return;
    } else if (Pw !== PwConfirm) {
      modal.warning({
        content: t('modal.join.warnning.not_equal_password'),
        okText: t('modal.button.ok')
      });
      return;
    }

    ApiMain(API_LIST.SIGN_UP_INFO, METHOD.POST, {
      id: Email,
      pw: Pw,
      company: Company,
      first_name: Name
    }, accessToken).then((res) => {
      switch (res.status) {
        case API_RESPONSE_CODE.OK:
        case API_RESPONSE_CODE.CREATED:
          modal.info({
            content: t('modal.join.welcome'),
            okText: t('modal.button.ok'),
            onOk: () => {
              window.sessionStorage.setItem('signInId', Email);

              if (res.headers.hasOwnProperty('emblaze-at-v1')) {
                setAccessToken(res.headers['emblaze-at-v1']);
                setRefreshToken(res.headers['emblaze-rt-v1']);
                window.sessionStorage.setItem('accessToken', res.headers['emblaze-at-v1']);
                window.sessionStorage.setItem('refreshToken', res.headers['emblaze-rt-v1']);
              }

              window.sessionStorage.setItem('loginDatetime', new Date());
              setID(Email);

              for (const e of BRAND_URL) {
                if (window.location.pathname.includes(e.url)) {
                  navigate(e.url + ROUTE_URL_LIST[ROUTE_URL_INDEX.PROJECT].url, { replace: true });
                  return;
                }
              }
            }
          });
          break;

        case API_RESPONSE_CODE.NO_CONTENT:
          modal.warning({
            content: t('modal.join.warnning.no_account'),
            okText: t('modal.button.ok')
          });
          break;

        default:
          modal.warning({
            content: t('modal.common.warnning.unkown_response') + res.status,
            okText: t('modal.button.ok')
          });
          break;
      }
    }).catch((err) => {
      switch (err.response.status) {
        case API_RESPONSE_CODE.PRECONDITION_FAILED:
          Refresh_API();
          break;
          
        case API_RESPONSE_CODE.UNAUTHORIZED:
          modal.error({
            content: t('modal.common.warnning.unauthorized'),
            okText: t('modal.button.ok')
          });
          break;

        case API_RESPONSE_CODE.NOT_ACCEPTABLE:
          modal.error({
            content: t('modal.common.warnning.not_acceptable'),
            okText: t('modal.button.ok')
          });
          break;

        case API_RESPONSE_CODE.BAD_REQUEST:
          modal.error({
            content: t('modal.common.warnning.bad_request'),
            okText: t('modal.button.ok')
          });
          break;

        case API_RESPONSE_CODE.SERVICE_UNAVAILABLE:
          modal.error({
            content: t('modal.common.warnning.server_error'),
            okText: t('modal.button.ok')
          });
          break;

        default:
          modal.error({
            content: t('modal.common.warnning.unknown_error') + err.response.status,
            okText: t('modal.button.ok')
          });
          break;
      }
    });
  };

  useEffect(() => {
    const email = (new URLSearchParams(window.location.search)).get('id');

    if (ID) {
      for (const e of BRAND_URL) {
        if (window.location.pathname.includes(e.url)) {
          navigate(e.url + ROUTE_URL_LIST[ROUTE_URL_INDEX.PROJECT].url, { replace: true });
          return;
        }
      }
    } else if (email != null) {
      setEmail(email);
    }
  }, []);

  return (
    <div className='join'>
      <Space className='space_center content'>
        <Card>
          <Space direction='vertical' size={15}>
            <Space className='space_center card_content' direction='vertical'>
              <Space size={0}>
                <Image src='/img/emblaze.svg' width={100} preview={false} />
                {t('modal.join.description_line_1')}
              </Space>
              {t('modal.join.description_line_2')}
            </Space>
            <Input value={Email} size='large' prefix={<MailOutlined />} disabled={true} />
            <Input.Password value={Pw} size='large' placeholder={t('modal.join.placeholder.password')} prefix={<LockOutlined />} onChange={(e) => setPw(e.target.value)} />
            <Input.Password value={PwConfirm} size='large' placeholder={t('modal.join.placeholder.password_confirm')} prefix={<LockOutlined />} onChange={(e) => setPwConfirm(e.target.value)} />
            <Input value={Company} size='large' placeholder={t('modal.join.placeholder.company')} prefix={<HomeOutlined />} onChange={(e) => setCompany(e.target.value)} />
            <Input value={Name} size='large' placeholder={t('modal.join.placeholder.name')} prefix={<TagOutlined />} onChange={(e) => setName(e.target.value)} />
            <Button className='button' type='primary' size='large' block onClick={onClick_SignUp}>{t('modal.join.button.join')}</Button>
          </Space>
        </Card>
      </Space>
    </div>
  );
}