import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Input, Button, Tooltip, Typography, Space, Tag, App } from 'antd';
import { PlusOutlined, PlusSquareOutlined } from '@ant-design/icons';
import { DesignContext } from '../../pages/Design';
import { API_LIST, API_RESPONSE_CODE, ApiMain, METHOD } from '../../common/Api';
import { AppContext } from '../../App';

const { Text } = Typography;

export default function ModalZoneCreate() {
  const { t } = useTranslation();
  const { modal } = App.useApp();
  const { ID, accessToken, Refresh_API } = useContext(AppContext);
  const { field, GetNodes } = useContext(DesignContext);

  const [ModalOpen, setModalOpen] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [ZoneName, setZoneName] = useState('');
  const [ZoneNameWarnning, setZoneNameWarnning] = useState(false);

  const onClick_Modal = () => {
    setZoneName('');
    setZoneNameWarnning(false);
    setModalOpen(true);
  };

  const onCancel_Modal = () => {
    setModalOpen(false);
  };

  const onOk_Modal = () => {
    if (ZoneName.length === 0) {
      setZoneNameWarnning(true);
      return;
    }

    setLoading(true);
    ApiMain(API_LIST.ZONE_CREATE, METHOD.POST, {
      id: ID,
      f_idx: field.f_idx,
      z_name: ZoneName
    }, accessToken).then((res) => {
      switch (res.status) {
        case API_RESPONSE_CODE.CREATED:
          GetNodes();
          setModalOpen(false);
          break;

        default:
          modal.warning({
            content: t('modal.common.warnning.unkown_response') + res.status,
            okText: t('modal.button.ok')
          });
          break;
      }

      setLoading(false);
    }).catch((err) => {
      switch (err.response.status) {
        case API_RESPONSE_CODE.PRECONDITION_FAILED:
          Refresh_API();
          break;
        
        case API_RESPONSE_CODE.UNAUTHORIZED:
          modal.error({
            content: t('modal.common.warnning.unauthorized'),
            okText: t('modal.button.ok')
          });
          break;

        case API_RESPONSE_CODE.NOT_ACCEPTABLE:
          modal.error({
            content: t('modal.common.warnning.not_acceptable'),
            okText: t('modal.button.ok')
          });
          break;

        case API_RESPONSE_CODE.BAD_REQUEST:
          modal.error({
            content: t('modal.common.warnning.bad_request'),
            okText: t('modal.button.ok')
          });
          break;

        case API_RESPONSE_CODE.SERVICE_UNAVAILABLE:
          modal.error({
            content: t('modal.common.warnning.server_error'),
            okText: t('modal.button.ok')
          });
          break;

        default:
          modal.error({
            content: t('modal.common.warnning.unknown_error') + err.response.status,
            okText: t('modal.button.ok')
          });
          break;
      }

      setLoading(false);
    });
  };

  const onChange_ZoneName = (e) => {
    setZoneName(e.target.value);
    setZoneNameWarnning(false);
  }

  return (
    <>
      <Tooltip title={t('design.list.tooltip.create_group')}>
        <Button type='primary' icon={<PlusOutlined />} onClick={onClick_Modal} />
      </Tooltip>

      <Modal closable={false} open={ModalOpen} confirmLoading={Loading} onOk={onOk_Modal} onCancel={onCancel_Modal} okText={t('modal.button.ok')} cancelText={t('modal.button.cancel')}
        title={
          <Space>
            <PlusSquareOutlined />
            {t('modal.zone.create.title')}
          </Space>}>
        <Space direction='vertical space_center'>
          <Space className='modal_space_warnning'>
            <Tag className='modal_subtitle' color='#F4BD40' style={{ color: '#222222' }}>{t('modal.zone.create.zone_name')}</Tag>
            <Space direction='vertical' size={0}>
              <Input className='modal_input' value={ZoneName} onChange={onChange_ZoneName} />
              {ZoneNameWarnning ? <Text className='modal_error_text'>{t('modal.zone.create.warnning.no_zone_name')}</Text> : null}
            </Space>
          </Space>
        </Space>
      </Modal>
    </>
  );
}