import React, { useState, useEffect, createContext, useContext } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Card, Col, Row, Space, Menu, Tag, Typography, Drawer, Select, Divider, Tooltip, Button } from 'antd';
import { LayoutOutlined, UsergroupAddOutlined, AreaChartOutlined, DesktopOutlined } from '@ant-design/icons';
import { API_LIST, API_RESPONSE_CODE, ApiMain, METHOD } from '../common/Api';
import { TimestampConvert, TimezoneConvert } from '../common/Util';
import { BRAND_URL } from '../common/Brand';
import { AppContext } from '../App';
import ModalProjectDelete from '../components/project/ModalProjectDelete';
import ModalProjectUpdate from '../components/project/ModalProjectUpdate';
import ModalProjectCreate from '../components/project/ModalProjectCreate';
import ModalBuildingCreate from '../components/building/ModalBuildingCreate';
import ModalBuildingUpdate from '../components/building/ModalBuildingUpdate';
import ModalBuildingDelete from '../components/building/ModalBuildingDelete';
import ModalFieldCreate from '../components/field/ModalFieldCreate';
import FieldImage from '../components/field/FieldImage';
import Role from './Role';
import EnergyChart from '../components/energy/EnergyChart';
import Monitoring from '../components/monitoring/Monitoring';

const { Text } = Typography;

export const ProjectContext = createContext();

export default function Projects() {
  const MENU = {
    PLANNER: '0',
    ENERGY: '1',
    MONITORING: '2'
  };

  const { t } = useTranslation();
  const { ID, accessToken, Refresh_API, ROUTE_URL_LIST, ROUTE_URL_INDEX } = useContext(AppContext);

  const [Projects, setProjects] = useState([]);
  const [Buildings, setBuildings] = useState([]);
  const [Fields, setFields] = useState([]);
  const [SelectedProject, setSelectedProject] = useState();
  const [SelectedBuilding, setSelectedBuilding] = useState();
  const [RunningCount, setRunningCount] = useState([]);
  const [AssignedCount, setAssignedCount] = useState([]);
  const [NotAssignedCount, setNotAssignedCount] = useState([]);
  const [SelectedMenu, setSelectedMenu] = useState((window.sessionStorage.project_menu != null) ? window.sessionStorage.project_menu : MENU.MONITORING);
  const [OpenDrawerRole, setOpenDrawerRole] = useState(false);

  const SetViewHistory = (pj_idx, bldg_idx, f_idx) => {
    ApiMain(API_LIST.VIEW_HISTORY_SET, METHOD.POST, {
      id: ID,
      pj_idx: pj_idx,
      bldg_idx: bldg_idx,
      f_idx: f_idx
    }, accessToken).then((res) => {
      // console.log(res);
    }).catch((err) => {
      console.log(err);
    });
  };

  const GetProjects = () => {
    ApiMain(API_LIST.PROJECT_GET, METHOD.POST, {
      id: ID
    }, accessToken).then((res) => {
      switch (res.status) {
        case API_RESPONSE_CODE.OK:
          res.data.forEach((e) => {
            e.children = [];
          });
          setProjects(res.data);

          if (window.sessionStorage.pj_idx != null) {
            for (const e of res.data) {
              if (e.pj_idx === parseInt(window.sessionStorage.pj_idx)) {
                setSelectedProject(e);
                GetBuildings(e.pj_idx, res.data);
                break;
              }
            }
          }
          break;

        default:
          break;
      }
    }).catch(async (err) => {
      console.log(err);

      if (err.response.status === API_RESPONSE_CODE.PRECONDITION_FAILED) {
        Refresh_API();
      }
    });
  };

  const GetBuildings = (pj_idx, projects) => {
    ApiMain(API_LIST.BUILDING_GET, METHOD.POST, {
      id: ID,
      pj_idx: pj_idx
    }, accessToken).then((res) => {
      switch (res.status) {
        case API_RESPONSE_CODE.OK:
          let new_projects = [];
          projects.forEach((e) => {
            if (e.pj_idx === pj_idx) {
              let buildings = [];
              res.data.forEach((_e) => {
                buildings.push({
                  key: 'bldg_' + _e.bldg_idx,
                  label: _e.bldg_name,
                  data: _e
                });
              });
              e.children = buildings;
            }
            new_projects.push(e);
          });
          setProjects(new_projects);

          setBuildings(res.data);

          if (window.sessionStorage.bldg_idx != null) {
            for (const e of res.data) {
              if (e.bldg_idx === parseInt(window.sessionStorage.bldg_idx)) {
                setSelectedBuilding(e);
                GetFields(e.bldg_idx);
                break;
              }
            }
          }
          break;

        default:
          break;
      }
    }).catch((err) => {
      console.log(err);

      if (err.response.status === API_RESPONSE_CODE.PRECONDITION_FAILED) {
        Refresh_API();
      }
    });
  };

  const GetFields = (bldg_idx) => {
    ApiMain(API_LIST.FIELD_GET, METHOD.POST, {
      id: ID,
      bldg_idx: bldg_idx
    }, accessToken).then((res) => {
      switch (res.status) {
        case API_RESPONSE_CODE.OK:
          setFields(res.data);

          if (window.sessionStorage.f_idx != null) {
            for (const e of res.data) {
              if (e.f_idx === parseInt(window.sessionStorage.f_idx)) {
                window.sessionStorage.setItem('f_idx', e.f_idx);
                break;
              }
            }
          }
          break;

        default:
          break;
      }
    }).catch((err) => {
      console.log(err);

      if (err.response.status === API_RESPONSE_CODE.PRECONDITION_FAILED) {
        Refresh_API();
      }
    });
  };

  const GetNodeList = async () => {
    let sumArrayRunning = [];
    let sumArrayAssigned = [];
    let sumArrayNotAssigned = [];
    for (const f_list of Fields) {
      let res = await ApiMain(API_LIST.ZONE_BATCH_GET, METHOD.POST, {
        id: ID,
        f_idx: f_list.f_idx
      }, accessToken);

      let sumRunning = 0;
      let sumAssigned = 0;
      let sumNotAssigned = 0;
      for (const e of res.data) {
        for (const _e of e.pn) {
          if (_e.process === 3) {
            sumRunning += 1;
          } else if (_e.process === 2) {
            sumAssigned += 1;
          } else if (_e.process === 1) {
            sumNotAssigned += 1;
          }
        }
      }

      sumArrayRunning.push(sumRunning);
      sumArrayAssigned.push(sumAssigned);
      sumArrayNotAssigned.push(sumNotAssigned);
    }

    setRunningCount(sumArrayRunning);
    setAssignedCount(sumArrayAssigned);
    setNotAssignedCount(sumArrayNotAssigned);
  };

  const onUpdate_Project = (values) => {
    let update_selected_project = { ...SelectedProject };
    update_selected_project.pj_name = values.pj_name;
    setSelectedProject(update_selected_project);
    window.sessionStorage.setItem('pj_idx', update_selected_project.pj_idx);
    window.sessionStorage.setItem('bldg_idx', null);
    window.sessionStorage.setItem('f_idx', null);

    let update_projects = [];
    for (let e of Projects) {
      if (e.pj_idx === update_selected_project.pj_idx) {
        update_projects.push(update_selected_project);
      } else {
        update_projects.push(e);
      }
    }
    setProjects(update_projects);
  };

  const onReload_Project = () => {
    setSelectedProject(null);
    setSelectedBuilding(null);
    setFields([]);
    GetProjects();
  };

  const onUpdate_Building = (values) => {
    let update_selected_building = { ...SelectedBuilding };
    update_selected_building.bldg_name = values.bldg_name;
    update_selected_building.address = values.address;
    setSelectedBuilding(update_selected_building);
    window.sessionStorage.setItem('bldg_idx', update_selected_building.bldg_idx);

    let update_projects = [];
    for (let e of Projects) {
      if (e.pj_idx === update_selected_building.pj_idx) {
        let update_buildings = [];
        for (let _e of e.children) {
          if (_e.key.replace('bldg_', '') === update_selected_building.bldg_idx) {
            _e.label = values.bldg_name;
            _e.data = update_selected_building;
            update_buildings.push(_e);
          } else {
            update_buildings.push(_e);
          }
        }
        e.children = update_buildings;
      }
      update_projects.push(e);
    }
    setProjects(update_projects);
  };

  const onReload_Building = () => {
    setSelectedBuilding(null);
    setFields([]);
    GetBuildings(SelectedProject.pj_idx, Projects);
  };

  const onReload_Field = (timeout) => {
    setFields([]);

    setTimeout(() => {
      GetFields(SelectedBuilding.bldg_idx)
    }, timeout);
  };

  const onChange_Projects = (value, option) => {
    setBuildings([]);
    setSelectedBuilding(null);
    Projects.forEach((e) => {
      if (e.pj_idx === option.key) {
        setSelectedProject(e);
        window.sessionStorage.setItem('pj_idx', e.pj_idx);
        GetBuildings(e.pj_idx, Projects);
      }
    });
    SetViewHistory(option.key, null, null);
  };

  const onChange_Buildings = (value, option) => {
    setFields([]);
    Buildings.forEach((e) => {
      if (e.bldg_idx === option.key) {
        setSelectedBuilding(e);
        window.sessionStorage.setItem('bldg_idx', e.bldg_idx);
        GetFields(e.bldg_idx);
      }
    });
    SetViewHistory(SelectedProject.pj_idx, option.key, null);
  };

  useEffect(() => {
    GetProjects();
  }, []);

  useEffect(() => {
    GetNodeList();
  }, [Fields]);

  return (
    <div className='projects space_center_vertical'>
      <ProjectContext.Provider value={{
        SelectedProject, SelectedBuilding, onUpdate_Project, onUpdate_Building, onReload_Project, onReload_Building, SetViewHistory,
        Fields, RunningCount, AssignedCount, NotAssignedCount, onReload_Field
      }}>

        <Space className='content' direction='vertical'>
          <Space className='main_header' direction='vertical'>
            <Space>
              <Tag className='space_center tag_title' color='#F4BD40'>{t('project.project_info.title')}</Tag>
              <Select className='select' size='large' notFoundContent={t('project.project_info.no_data')} value={(SelectedProject != null) ? SelectedProject.pj_name : null} placeholder={t('project.project_info.placeholder')} onChange={onChange_Projects}
                options={Projects.map((value) => ({ key: value.pj_idx, value: value.pj_name, label: '(' + value.pj_idx + ') ' + value.pj_name }))} />
              <Space className='space_edit_menu'>
                <ModalProjectCreate />
                {(SelectedProject != null) ?
                  <>
                    <ModalProjectUpdate />
                    <ModalProjectDelete />
                  </> : null}
              </Space>
              {(SelectedProject != null) ?
                <>
                  <Divider className='divider_edit_menu' type='vertical' />
                  <Space size={15}>
                    <Text>{TimestampConvert(SelectedProject.create_timestamp)}</Text>
                    <Text>{TimezoneConvert(SelectedProject.utc)}</Text>
                  </Space>
                </> : null}
            </Space>
            <Space>
              <Tag className='space_center tag_title' color='#F4BD40'>{t('project.building_info.title')}</Tag>
              <Select className='select' size='large' notFoundContent={t('project.building_info.no_data')} value={(SelectedBuilding != null) ? SelectedBuilding.bldg_name : null} placeholder={t('project.building_info.placeholder')} onChange={onChange_Buildings}
                options={Buildings.map((value) => ({ key: value.bldg_idx, value: value.bldg_name, label: '(' + value.bldg_idx + ') ' + value.bldg_name }))} />
              <Space className='space_edit_menu'>
                {(SelectedProject != null) ? <ModalBuildingCreate /> : null}
                {(SelectedBuilding != null) ?
                  <>
                    <ModalBuildingUpdate />
                    <ModalBuildingDelete />
                    <Tooltip title={t('project.building_info.menu.manage_user')}>
                      <Button size='large' icon={<UsergroupAddOutlined />} onClick={() => setOpenDrawerRole(true)}></Button>
                    </Tooltip>
                  </> : null}
              </Space>
              {(SelectedBuilding != null) ?
                <>
                  <Divider className='divider_edit_menu' type='vertical' />
                  <Space size={15}>
                    <Text>{TimestampConvert(SelectedBuilding.create_timestamp)}</Text>
                    <Text>{SelectedBuilding.address}</Text>
                  </Space>
                </> : null}
            </Space>
          </Space>

          {(SelectedBuilding != null) ?
            <Space className='main_content' direction='vertical'>
              <Menu className='menu' theme='dark' selectedKeys={[SelectedMenu]} mode='horizontal' onSelect={(info) => { setSelectedMenu(info.key); window.sessionStorage.setItem('project_menu', info.key); }}
                items={[{
                  key: MENU.MONITORING,
                  label: t('main_menu.title.monitoring'),
                  icon: <DesktopOutlined style={{ fontSize: 20 }} />,
                  className: 'menu_item'
                }, {
                  key: MENU.ENERGY,
                  label: t('main_menu.title.energy'),
                  icon: <AreaChartOutlined style={{ fontSize: 20 }} />,
                  className: 'menu_item'
                }, {
                  key: MENU.PLANNER,
                  label: t('main_menu.title.planner'),
                  icon: <LayoutOutlined style={{ fontSize: 20 }} />,
                  className: 'menu_item'
                }]} />

              {(SelectedMenu === MENU.MONITORING) ? <Monitoring /> : null}
              {(SelectedMenu === MENU.ENERGY) ? <EnergyChart /> : null}
              {(SelectedMenu === MENU.PLANNER) ?
                <Card title={<Text className='card_title'>{t('project.field_info.title')}</Text>} extra={<ModalFieldCreate />}>
                  {((Fields.length === 0)) ? ((SelectedBuilding == null) ? t('project.field_info.placeholder.content.no_select') : t('project.field_info.placeholder.content.no_data')) :
                    <Row gutter={(16, 16)}>
                      {Fields.map((f, index) => (
                        <Col key={f.f_idx} span={8}>
                          <Link to={
                            (() => {
                              for (const e of BRAND_URL) {
                                if (window.location.pathname.includes(e.url)) {
                                  return (e.url + ROUTE_URL_LIST[ROUTE_URL_INDEX.DESIGN].url);
                                }
                              }
                            })()}
                            state={{ project: SelectedProject, building: SelectedBuilding, field: f }}>
                            <Card>
                              <Space direction='vertical'>
                                <FieldImage img_url={f.img_url} width={'calc(100vw / 3 - 82px)'} height={'calc(100vw / 3 - 82px)'} preview={false} />
                                <Space direction='vertical'>
                                  <Space>
                                    <Tag className='tag_node' color='#F4BD40' style={{ color: '#222222' }}>{t('project.field_info.subtitle.name')}</Tag>
                                    {f.f_name}
                                  </Space>
                                  <Space>
                                    <Tag className='tag_node' color='#F4BD40' style={{ color: '#222222' }}>{t('project.field_info.subtitle.created_datetime')}</Tag>
                                    {TimestampConvert(f.create_timestamp)}
                                  </Space>
                                  <Space direction='vertical' style={{ marginTop: 10 }}>
                                    <Space>
                                      <Tag className='tag_node' color='#177DDC' style={{ color: '#FFFFFF' }}>{t('project.field_info.tag.running')}</Tag>
                                      {RunningCount[index]}
                                    </Space>
                                    <Space>
                                      <Tag className='tag_node' color='#088A29' style={{ color: '#FFFFFF' }}>{t('project.field_info.tag.assigned')}</Tag>
                                      {AssignedCount[index]}
                                    </Space>
                                    <Space>
                                      <Tag className='tag_node' color='#B40404' style={{ color: '#FFFFFF' }}>{t('project.field_info.tag.not_assigned')}</Tag>
                                      {NotAssignedCount[index]}
                                    </Space>
                                  </Space>
                                </Space>
                              </Space>
                            </Card>
                          </Link>
                        </Col>
                      ))}
                    </Row>}
                </Card> : null}
            </Space> :
            <Space className='empty space_center'>{t('project.select_pj_bldg')}</Space>}
        </Space>

        <Drawer closable={false} open={OpenDrawerRole} onClose={() => setOpenDrawerRole(false)} width={990}>
          <Role />
        </Drawer>

      </ProjectContext.Provider>
    </div>
  );
}