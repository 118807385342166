import React, { useState } from 'react';
import { Button, Select, Space, Modal, Tag } from 'antd';
import { GlobalOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

export default function ModalLanguageChange(props) {
  const { t, i18n } = useTranslation();

  const [ModalOpen, setModalOpen] = useState(false);
  const [LanguageName, setLanguageName] = useState();
  const [LanguageKey, setLanguageKey] = useState();

  const LANGUAGE = [
    { key: 0, value: '한국어', code: 'ko' },
    { key: 1, value: 'English', code: 'en' }
  ];

  const onClick_Modal = () => {
    setLanguageName();
    setModalOpen(true);
  };

  const onCancel_Modal = () => {
    setModalOpen(false);
  };

  const onOk_Modal = () => {
    if (LanguageKey != null) {
      for (const e of LANGUAGE) {
        if (LanguageKey === e.key) {
          i18n.changeLanguage(e.code);
          break;
        }
      }
    }
    setModalOpen(false);
  };

  const onChange_Language = (value, options) => {
    setLanguageName(value);
    setLanguageKey(options.key);
  };

  return (
    <>
      <Button className={(props.type === 0) ? 'header_menu_button' : 'language_button'} icon={<GlobalOutlined style={(props.type === 1) ? { color: '#F4BD40' } : null} />} onClick={onClick_Modal}>{t('main_menu.setting.language')}</Button>

      <Modal closable={false} open={ModalOpen} onOk={onOk_Modal} onCancel={onCancel_Modal} okText={t('modal.button.ok')} cancelText={t('modal.button.cancel')}
        title={
          <Space>
            <GlobalOutlined />
            {t('modal.change_language.title')}
          </Space>}>
        <Space className='modal_content space_center' direction='vertical'>
          <Space>
            <Tag className='modal_subtitle' color='#F4BD40' style={{ color: '#222222' }}>{t('modal.change_language.subtitle.select_language')}</Tag>
            <Select className='modal_input' placeholder={t('modal.change_language.placeholder.select_language')} value={LanguageName} options={LANGUAGE} onChange={onChange_Language} />
          </Space>
        </Space>
      </Modal>
    </>
  );
}
