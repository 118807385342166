import { Scanner } from "@yudiel/react-qr-scanner";
import { API_LIST, API_RESPONSE_CODE, ApiMain, METHOD } from "../common/Api";
import { App, Button, Divider, Input, Space, Tag, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { useState } from "react";

const { Text, Title } = Typography;

export default function NodeSearch() {
  const { t } = useTranslation();
  const { modal } = App.useApp();

  const [DeviceUuid, setDeviceUuid] = useState('');

  const onScan = (result) => {
    let uuid = result[0].rawValue.split('UUID: ');
    if (uuid.length >= 2) {
      uuid = uuid[1].split('\nP/N: ')[0];
      NodeInfoGet(uuid);
    }
  };

  const onChange_DeviceUuid = (e) => {
    setDeviceUuid(e.target.value);
  };

  const onClick_DeviceUuid = (e) => {
    if ((DeviceUuid.length < 7) || !DeviceUuid.includes('-')) {
      modal.warning({
        content: '제어기 ID를 7자리로 입력해주세요. 예시) 03B1-26',
        okText: t('modal.button.ok')
      });
      return;
    }

    NodeInfoGet(DeviceUuid);
  };

  const NodeInfoGet = (uuid) => {
    ApiMain(API_LIST.NODE_INFO_GET, METHOD.POST, {
      device_uuid: uuid
    }).then((res) => {
      switch (res.status) {
        case API_RESPONSE_CODE.OK:
          modal.success({
            width: 600,
            title: '조명찾기',
            content:
              <Space direction='vertical'>
                <Space>
                  <Tag className='modal_subtitle' color='#F4BD40' style={{ color: '#222222' }}>제어기 ID</Tag>
                  <Text>{res.data.device_uuid}</Text>
                </Space>
                <Space>
                  <Tag className='modal_subtitle' color='#F4BD40' style={{ color: '#222222' }}>모델명</Tag>
                  <Text>{res.data.part_number}</Text>
                </Space>
                <Space>
                  <Tag className='modal_subtitle' color='#F4BD40' style={{ color: '#222222' }}>일련번호</Tag>
                  <Text>{res.data.serial_number}</Text>
                </Space>
                <Space>
                  <Tag className='modal_subtitle' color='#F4BD40' style={{ color: '#222222' }}>제조일자</Tag>
                  <Text>{res.data.manufactured_date}</Text>
                </Space>

                <Divider className='divider_normal' />

                <Space>
                  <Tag className='modal_subtitle' color='#F4BD40' style={{ color: '#222222' }}>프로젝트</Tag>
                  <Text>({res.data.pj_idx}) {res.data.pj_name}</Text>
                </Space>
                <Space>
                  <Tag className='modal_subtitle' color='#F4BD40' style={{ color: '#222222' }}>건물</Tag>
                  <Text>({res.data.bldg_idx}) {res.data.bldg_name}</Text>
                </Space>
                <Space>
                  <Tag className='modal_subtitle' color='#F4BD40' style={{ color: '#222222' }}>구역/층</Tag>
                  <Text>({res.data.f_idx}) {res.data.f_name}</Text>
                </Space>
                <Space>
                  <Tag className='modal_subtitle' color='#F4BD40' style={{ color: '#222222' }}>그룹</Tag>
                  <Text>({res.data.z_idx}) {res.data.z_name}</Text>
                </Space>
                <Space>
                  <Tag className='modal_subtitle' color='#F4BD40' style={{ color: '#222222' }}>소유자</Tag>
                  <Text>{res.data.user_id}</Text>
                </Space>

                <Divider className='divider_normal' />

                <Space>
                  <Tag className='modal_subtitle' color='#F4BD40' style={{ color: '#222222' }}>상태</Tag>
                  <Text>({res.data.process})</Text>
                </Space>
                <Space>
                  <Tag className='modal_subtitle' color='#F4BD40' style={{ color: '#222222' }}>위치좌표</Tag>
                  <Text>[{res.data.x}, {res.data.y}]</Text>
                </Space>
              </Space>,
            okText: t('modal.button.ok')
          });
          break;

        case API_RESPONSE_CODE.NO_CONTENT:
          modal.warning({
            content: t('modal.password_init.warnning.no_account'),
            okText: t('modal.button.ok')
          });
          break;

        default:
          modal.warning({
            content: t('modal.common.warnning.unkown_response') + res.status,
            okText: t('modal.button.ok')
          });
          break;
      }
    }).catch((err) => {
      switch (err.response.status) {
        case API_RESPONSE_CODE.UNAUTHORIZED:
          modal.error({
            content: t('modal.common.warnning.unauthorized'),
            okText: t('modal.button.ok')
          });
          break;

        case API_RESPONSE_CODE.NOT_ACCEPTABLE:
          modal.error({
            content: t('modal.common.warnning.not_acceptable'),
            okText: t('modal.button.ok')
          });
          break;

        case API_RESPONSE_CODE.BAD_REQUEST:
          modal.error({
            content: t('modal.common.warnning.bad_request'),
            okText: t('modal.button.ok')
          });
          break;

        case API_RESPONSE_CODE.SERVICE_UNAVAILABLE:
          modal.error({
            content: t('modal.common.warnning.server_error'),
            okText: t('modal.button.ok')
          });
          break;

        default:
          modal.error({
            content: t('modal.common.warnning.unknown_error') + err.response.status,
            okText: t('modal.button.ok')
          });
          break;
      }
    });
  };

  return (
    <Space className="space_center" direction="vertical">
      <Space>
        <Scanner onScan={onScan} />
      </Space>
      <Space className="space_center" direction="vertical">
        <Title level={3}>QR코드를 스캔 또는 하단 ID를 입력</Title>
        <Space>
          <Input className='modal_input' maxLength={7} value={DeviceUuid} onChange={onChange_DeviceUuid} />
          <Button type="primary" onClick={onClick_DeviceUuid}>검색</Button>
        </Space>
      </Space>
    </Space>
  );
}