import React, { useState, useEffect, useContext } from 'react';
import { App, Button, Input, Space, Typography, Modal, Tag } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { API_LIST, API_RESPONSE_CODE, ApiMain, METHOD } from '../../common/Api';
import { AppContext } from '../../App';

const { Text } = Typography;

export default function ModalUserInfoUpdate() {
  const { t } = useTranslation();
  const { modal } = App.useApp();
  const { ID, accessToken, Refresh_API } = useContext(AppContext);

  const [ModalOpen, setModalOpen] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [UserInfo, setUserInfo] = useState();
  const [Name, setName] = useState('');
  const [Company, setCompany] = useState('');
  const [NameWarnning, setNameWarnning] = useState(false);
  const [CompanyWarnning, setCompanyWarnning] = useState(false);

  const onClick_Modal = () => {
    setNameWarnning(false);
    setCompanyWarnning(false);
    setCompany(UserInfo.company);
    setName(UserInfo.name);
    setModalOpen(true);
  };

  const onCancel_Modal = () => {
    setModalOpen(false);
  };

  const onOk_Modal = () => {
    if (Name.length === 0) {
      setNameWarnning(true);
    }
    if (Company.length === 0) {
      setCompanyWarnning(true);
    }
    if ((Name.length === 0) || (Company.length === 0)) {
      return;
    }

    setLoading(true);
    ApiMain(API_LIST.USER_INFO_UPDATE, METHOD.POST, {
      id: ID,
      company: Company,
      first_name: Name
    }, accessToken).then((res) => {
      switch (res.status) {
        case API_RESPONSE_CODE.ACCEPTED:
          modal.success({
            content: t('modal.edit_account.complete'),
            okText: t('modal.button.ok'),
            onOk: () => {
              GetUserInfo();
              setModalOpen(false);
            }
          });
          break;

        case API_RESPONSE_CODE.NO_CONTENT:
          modal.warning({
            content: t('modal.edit_account.warnning.no_content'),
            okText: t('modal.button.ok')
          });
          break;

        default:
          modal.warning({
            content: t('modal.common.warnning.unkown_response') + res.status,
            okText: t('modal.button.ok')
          });
          break;
      }

      setLoading(false);
    }).catch((err) => {
      switch (err.response.status) {
        case API_RESPONSE_CODE.PRECONDITION_FAILED:
          Refresh_API();
          break;
          
        case API_RESPONSE_CODE.UNAUTHORIZED:
          modal.error({
            content: t('modal.common.warnning.unauthorized'),
            okText: t('modal.button.ok')
          });
          break;

        case API_RESPONSE_CODE.NOT_ACCEPTABLE:
          modal.error({
            content: t('modal.common.warnning.not_acceptable'),
            okText: t('modal.button.ok')
          });
          break;

        case API_RESPONSE_CODE.BAD_REQUEST:
          modal.error({
            content: t('modal.common.warnning.bad_request'),
            okText: t('modal.button.ok')
          });
          break;

        case API_RESPONSE_CODE.SERVICE_UNAVAILABLE:
          modal.error({
            content: t('modal.common.warnning.server_error'),
            okText: t('modal.button.ok')
          });
          break;

        default:
          modal.error({
            content: t('modal.common.warnning.unknown_error') + err.response.status,
            okText: t('modal.button.ok')
          });
          break;
      }

      setLoading(false);
    });
  };

  const onChange_Name = (e) => {
    setName(e.target.value);
    setNameWarnning(false);
  };

  const onChange_Company = (e) => {
    setCompany(e.target.value);
    setCompanyWarnning(false);
  };

  const GetUserInfo = () => {
    ApiMain(API_LIST.USER_INFO_GET, METHOD.POST, {
      id: ID
    }, accessToken).then((res) => {
      if (API_RESPONSE_CODE.OK) {
        setUserInfo(res.data);
      }
    }).catch((err) => {
      console.log(err);

      if (err.response.status === API_RESPONSE_CODE.PRECONDITION_FAILED) {
        Refresh_API();
      }
    });
  };

  useEffect(() => {
    GetUserInfo();
  }, []);

  return (
    <>
      <Button className='header_menu_button' icon={<EditOutlined />} onClick={onClick_Modal}>{t('main_menu.setting.edit_account')}</Button>

      <Modal closable={false} open={ModalOpen} confirmLoading={Loading} onOk={onOk_Modal} onCancel={onCancel_Modal} okText={t('modal.button.ok')} cancelText={t('modal.button.cancel')}
        title={
          <Space>
            <EditOutlined />
            {t('modal.edit_account.title')}
          </Space>}>
        <Space className='modal_content space_center' direction='vertical'>
          <Space className='modal_space_warnning'>
            <Tag className='modal_subtitle' color='#F4BD40' style={{ color: '#222222' }}>{t('modal.edit_account.subtitle.company')}</Tag>
            <Space direction='vertical' size={0}>
              <Input className='modal_input' value={Company} onChange={onChange_Company} />
              {CompanyWarnning ? <Text className='modal_error_text'>{t('modal.edit_account.warnning.no_company')}</Text> : null}
            </Space>
          </Space>
          <Space className='modal_space_warnning'>
            <Tag className='modal_subtitle' color='#F4BD40' style={{ color: '#222222' }}>{t('modal.edit_account.subtitle.name')}</Tag>
            <Space direction='vertical' size={0}>
              <Input className='modal_input' value={Name} onChange={onChange_Name} />
              {NameWarnning ? <Text className='modal_error_text'>{t('modal.edit_account.warnning.no_name')}</Text> : null}
            </Space>
          </Space>
        </Space>
      </Modal>
    </>
  );
}