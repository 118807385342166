import React, { useEffect, useState, useRef, createContext, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Alert, Segmented, Button, Tooltip, Space, Typography, Divider, Tag, Drawer, Radio, App } from 'antd';
import { BulbFilled, DeleteOutlined, EditOutlined, PlusCircleOutlined, DragOutlined, UnorderedListOutlined, RollbackOutlined, LayoutOutlined, FileTextFilled } from '@ant-design/icons';
import { API_LIST, API_RESPONSE_CODE, ApiMain, METHOD } from '../common/Api';
import { AppContext } from '../App';
import { BRAND_URL } from '../common/Brand';
import Profiles from './Profiles';
import ModalZoneCreate from '../components/design/ModalZoneCreate';
import ModalZoneUpdate from '../components/design/ModalZoneUpdate';
import ModalNodeEdit from '../components/design/ModalNodeEdit';
import FieldImage from '../components/field/FieldImage';
import ModalZoneDelete from '../components/design/ModalZoneDelete';
import ModalFieldUpdate from '../components/field/ModalFieldUpdate';
import ModalFieldDelete from '../components/field/ModalFieldDelete';

const { Text } = Typography;

export const DesignContext = createContext();

export default function Design() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const FieldRef = useRef(null);
  const { modal } = App.useApp();
  const { ID, accessToken, Refresh_API, ROUTE_URL_LIST, ROUTE_URL_INDEX } = useContext(AppContext);

  const TOOLS_MENU_LIST = {
    NORMAL: 'normal',
    NODE_ADD: 'add',
    GROUP_EDIT: 'zone_edit',
    LOC_EDIT: 'location_edit',
    DELETE: 'delete'
  };
  const TOOLS_LIST = [{
    label:
      <Space className='segmented space_center'>
        {t('design.editor.menu.normal')}
      </Space>,
    value: TOOLS_MENU_LIST.NORMAL
  }, {
    label:
      <Space className='segmented space_center'>
        <PlusCircleOutlined />
        {t('design.editor.menu.add')}
      </Space>,
    value: TOOLS_MENU_LIST.NODE_ADD
  }, {
    label:
      <Space className='segmented space_center'>
        <EditOutlined />
        {t('design.editor.menu.change_group')}
      </Space>,
    value: TOOLS_MENU_LIST.GROUP_EDIT
  }, {
    label:
      <Space className='segmented space_center'>
        <DragOutlined />
        {t('design.editor.menu.move_location')}
      </Space>,
    value: TOOLS_MENU_LIST.LOC_EDIT
  }, {
    label:
      <Space className='segmented space_center'>
        <DeleteOutlined />
        {t('design.editor.menu.delete')}
      </Space>,
    value: TOOLS_MENU_LIST.DELETE
  }];

  const [EditMode, setEditMode] = useState(TOOLS_MENU_LIST.NORMAL);
  const [EditContent, setEditContent] = useState(null);
  const [OpenDrawerProfile, setOpenDrawerProfile] = useState(false);
  const [SelectedZone, setSelectedZone] = useState();
  const [Zones, setZones] = useState([]);
  const [Nodes, setNodes] = useState([]);
  const [DesignWidth, setDesignWidth] = useState(0);
  const [DesignHeight, setDesignHeight] = useState(0);

  const onClose_DrawerProfile = () => {
    setOpenDrawerProfile(false);
  };

  const onClick_ReturnProject = () => {
    for (const e of BRAND_URL) {
      if (window.location.pathname.includes(e.url)) {
        navigate(e.url + ROUTE_URL_LIST[ROUTE_URL_INDEX.PROJECT].url, { replace: true });
        break;
      }
    }
  };

  const UpdateToolsMenuContent = (value) => {
    if (value === TOOLS_MENU_LIST.NODE_ADD) {
      setEditContent(<Alert message={t('design.editor.menu_description.node_add')} type='warning' showIcon={true} />);
    } else if (value === TOOLS_MENU_LIST.GROUP_EDIT) {
      setEditContent(<Alert message={t('design.editor.menu_description.group_edit')} type='warning' showIcon={true} />);
    } else if (value === TOOLS_MENU_LIST.LOC_EDIT) {
      setEditContent(<Alert message={t('design.editor.menu_description.location_edit')} type='warning' showIcon={true} />);
    } else if (value === TOOLS_MENU_LIST.DELETE) {
      setEditContent(<Alert message={t('design.editor.menu_description.delete')} type='warning' showIcon={true} />);
    } else {
      setEditContent(<Alert message={t('design.editor.menu_description.select_work')} type='warning' style={{ visibility: 'hidden' }} />);
    }
  };

  const onChange_SegmentedEditMode = (value) => {
    setEditMode(value);
    UpdateToolsMenuContent(value);
    GetNodes();
  };

  const GetNodes = () => {
    ApiMain(API_LIST.ZONE_BATCH_GET, METHOD.POST, {
      id: ID,
      f_idx: location.state.field.f_idx
    }, accessToken).then((res) => {
      switch (res.status) {
        case API_RESPONSE_CODE.OK:
          /******* Nodes *******/
          let old_nodes = [...Nodes];
          let new_nodes = [];

          // New Nodes Copy
          res.data.forEach((e1) => {
            e1.pn.forEach((e2) => {
              e2.z_name = e1.z_name;
              new_nodes.push(e2);
            });
          });
          // Push New Nodes
          for (const e1 of new_nodes) {
            let exist = false;
            for (let i = 0; i < old_nodes.length; i++) {
              if (old_nodes[i] != null) {
                if (e1.pn_idx === old_nodes[i].pn_idx) {
                  old_nodes[i] = { ...e1 };
                  exist = true;
                  break;
                }
              }
            }
            if (!exist) {
              old_nodes.push(e1);
            }
          }
          // Delete No Exist Nodes
          for (let i = 0; i < old_nodes.length; i++) {
            if (old_nodes[i] != null) {
              let exist = false;
              for (const e1 of new_nodes) {
                if (old_nodes[i].pn_idx === e1.pn_idx) {
                  exist = true;
                  break;
                }
              }
              if (!exist) {
                old_nodes[i] = null;
              }
            }
          }

          let null_check = true;
          for (const e of old_nodes) {
            if (e != null) {
              null_check = false;
              break;
            }
          }
          if (null_check) {
            setNodes([]);
          } else {
            setNodes(old_nodes);
          }
          /******* Nodes *******/

          /******* Zones *******/
          let old_zones = [...Zones];
          let new_zones = res.data;

          // Push New Zones
          for (const e1 of new_zones) {
            let exist = false;
            for (let i = 0; i < old_zones.length; i++) {
              if (old_zones[i] != null) {
                if (e1.z_idx === old_zones[i].z_idx) {
                  old_zones[i] = { ...e1 };
                  exist = true;
                  break;
                }
              }
            }
            if (!exist) {
              old_zones.push(e1);
            }
          }
          // Delete No Exist Zones
          for (let i = 0; i < old_zones.length; i++) {
            if (old_zones[i] != null) {
              let exist = false;
              for (const e1 of new_zones) {
                if (old_zones[i].z_idx === e1.z_idx) {
                  exist = true;
                  break;
                }
              }
              if (!exist) {
                old_zones[i] = null;
              }
            }
          }

          null_check = true;
          for (const e of old_zones) {
            if (e != null) {
              if (e.z_idx !== -1) {
                null_check = false;
                break;
              }
            }
          }
          if (null_check) {
            setZones([]);
          } else {
            setZones(old_zones);
          }
          /******* Zones *******/
          break;

        default:
          break;
      }
    }).catch((err) => {
      console.log(err);

      if (err.response.status === API_RESPONSE_CODE.PRECONDITION_FAILED) {
        Refresh_API();
      }
    });
  };

  const CreateNode = (e) => {
    if (SelectedZone == null) {
      modal.warning({
        content: t('modal.design.create_node.warnning.select_group'),
        okText: t('modal.button.ok')
      });
      return;
    }

    const rect = e.target.getBoundingClientRect();
    const target = e.target.getAttribute('class');
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    const x_per = (x / rect.width) * 100;
    const y_per = (y / rect.height) * 100;

    if ((x_per >= 0) && (x_per <= 100) && (y_per >= 0) && (y_per <= 100)) {
      if (target != null) {
        if (target.includes('ant-image-img')) {
          ApiMain(API_LIST.PROVISION_NODE_CREATE, METHOD.POST, {
            id: ID,
            f_idx: location.state.field.f_idx,
            z_idx: SelectedZone.z_idx,
            x: x_per,
            y: y_per,
            process: (SelectedZone.z_idx === -1) ? 0 : 1
          }, accessToken).then((res) => {
            switch (res.status) {
              case API_RESPONSE_CODE.CREATED:
                GetNodes();
                break;

              default:
                break;
            }
          }).catch((err) => {
            console.log(err);

            if (err.response.status === API_RESPONSE_CODE.PRECONDITION_FAILED) {
              Refresh_API();
            }
          });
        }
      }
    }
  };

  const onLoad_FieldImage = () => {
    setDesignWidth(FieldRef.current.clientWidth);
    setDesignHeight(FieldRef.current.clientHeight);
  };

  useEffect(() => {
    GetNodes();
    UpdateToolsMenuContent(TOOLS_MENU_LIST.NORMAL);
  }, []);

  return (
    <div className='design'>
      <DesignContext.Provider value={{ field: location.state.field, SelectedZone, Zones, GetNodes, onClose_DrawerProfile, onClick_ReturnProject, TOOLS_MENU_LIST }}>

        <Space className='content' direction='vertical'>
          <Space className='title_header'>
            <Space className='title_header_text_space space_center'>
              <LayoutOutlined className='icon' />
              <Text className='title_header_text'>{t('design.title')}</Text>
            </Space>
          </Space>

          <Space className='main_header' direction='vertical'>
            <Space className='space_between'>
              <Space>
                <Tag className='space_center tag_title' color='#F4BD40'>{t('design.project_info.subtitle.project_name')}</Tag>
                <Text className='title'>{location.state.project.pj_name}</Text>
                <Divider className='divider_title' type='vertical' />
                <Tag className='space_center tag_title' color='#F4BD40'>{t('design.project_info.subtitle.building_name')}</Tag>
                <Text className='title'>{location.state.building.bldg_name}</Text>
                <Divider className='divider_title' type='vertical' />
                <Tag className='space_center tag_title' color='#F4BD40'>{t('design.project_info.subtitle.field_name')}</Tag>
                <Text className='title'>{location.state.field.f_name}</Text>
              </Space>
              <Space>
                <Divider className='divider_title' type='vertical' />
                <Space>
                  <ModalFieldUpdate f={location.state.field} />
                  <ModalFieldDelete f_idx={location.state.field.f_idx} />
                </Space>
                <Divider className='divider_title' type='vertical' />
                <Button style={{ marginLeft: 20 }} className='button_back' type='primary' icon={<RollbackOutlined />} block onClick={onClick_ReturnProject}>{t('design.button.exit')}</Button>
              </Space>
            </Space>
          </Space>

          <Space className='space_start'>
            <Space direction='vertical' size={0}>
              <Space className='group_header space_between'>
                <Space>
                  <UnorderedListOutlined className='icon_primary' />
                  <Text className='group_header_text'>{t('design.list.title')}</Text>
                </Space>
                <ModalZoneCreate />
              </Space>
              <Space className='group space_between'>
                <Space>
                  <Radio checked={(SelectedZone == null)} onChange={() => setSelectedZone(null)} />
                  <Space direction='vertical' size={0}>
                    <Text className='icon_outlined_primary'>{t('design.list.subtitle.all_light_num')}</Text>
                    <Space>
                      <BulbFilled className='icon_outlined_primary' />
                      <Text className='icon_outlined_primary'>{
                        (() => {
                          let length = 0;
                          for (const e of Nodes) {
                            if (e != null) {
                              length++;
                            }
                          }
                          return length;
                        })()
                      }</Text>
                    </Space>
                  </Space>
                </Space>
                <Tooltip title={t('design.list.tooltip.profile_setting')}>
                  <Button icon={<FileTextFilled type='primary' />} disabled={(Zones.length === 0)} onClick={() => setOpenDrawerProfile(true)}>{t('design.button.profile')}</Button>
                </Tooltip>
              </Space>
              <Divider className='divider_group_info' />
              {Zones.map((z, i) => (
                (z != null) ? (z.z_idx !== -1) ?
                  <div key={i}>
                    <Space className='group space_between'>
                      <Space>
                        <Radio checked={(SelectedZone != null) ? (SelectedZone.z_idx === z.z_idx) : false} onChange={() => setSelectedZone(z)} />
                        <Space direction='vertical' size={0}>
                          <Text>{z.z_name}</Text>
                          <Space>
                            <BulbFilled />
                            {z.pn.length}
                          </Space>
                        </Space>
                      </Space>
                      <Space>
                        <ModalZoneUpdate zone={z} />
                        <ModalZoneDelete key='delete' z_key={z.z_idx} deleteData={z.deleteData} />
                      </Space>
                    </Space>
                    <Divider className='divider_group' />
                  </div> : null : null))}
            </Space>

            <Space className='field_container' direction='vertical' size={0}>
              <Space className='space_center' direction='vertical' style={{ padding: 10 }}>
                <Segmented value={EditMode} options={TOOLS_LIST} onChange={onChange_SegmentedEditMode} />
                {EditContent}
              </Space>

              <Space className='space_center none_overflow'>
                <div className='space_center field' ref={FieldRef} onClick={(EditMode === TOOLS_MENU_LIST.NODE_ADD) ? CreateNode : null}>
                  <FieldImage cursor={(EditMode === TOOLS_MENU_LIST.NODE_ADD) ? 'crosshair' : 'default'} img_url={location.state.field.img_url} width='100%' height='100%' preview={false} onLoad={onLoad_FieldImage} />
                  {Nodes.map((node) => ((node != null) ? <ModalNodeEdit key={node.pn_idx} pn={node} EditMode={EditMode} DesignWidth={DesignWidth} DesignHeight={DesignHeight} /> : null))}
                </div>
              </Space>
            </Space>
          </Space>

          <Drawer closable={false} open={OpenDrawerProfile} width={610}>
            <Profiles width={610} />
          </Drawer>
        </Space>

      </DesignContext.Provider>
    </div>
  );
}