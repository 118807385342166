import React, { createContext, useEffect, useState } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { App as AntdApp, Layout, Space, Button, Dropdown, Image, Typography, Statistic } from 'antd';
import { UserOutlined, LogoutOutlined } from '@ant-design/icons';
import './App.css';
import Login from './pages/Login';
import Join from './pages/Join';
import Projects from './pages/Projects';
import Role from './pages/Role';
import Design from './pages/Design';
import Profiles from './pages/Profiles';
import ModalUserPasswordUpdate from './components/user/ModalUserPasswordUpdate';
import ModalUserInfoUpdate from './components/user/ModalUserInfoUpdate';
import ModalLanguageChange from './components/language/ModalLanguageChange';
import { BRAND_URL } from './common/Brand';
import Maintenance from './pages/Maintenance';
import { API_RESPONSE_CODE, ApiRefresh } from './common/Api';
import { Mutex, Semaphore, withTimeout } from 'async-mutex';
import NodeSearch from './pages/NodeSearch';

const { Header, Content, Footer } = Layout;
const { Text } = Typography;
const { Countdown } = Statistic;

export const AppContext = createContext();

export default function App() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { modal } = AntdApp.useApp();
  const mutex = new Mutex();

  const [ID, setID] = useState(window.sessionStorage.signInId);
  const [AccessToken, setAccessToken] = useState(window.sessionStorage.accessToken);
  const [RefreshToken, setRefreshToken] = useState(window.sessionStorage.refreshToken);
  const [LogoutRemainingTime, setLogoutRemainingTime] = useState(0);
  const [HomeLink, setHomeLink] = useState('');
  const [HeaderLogo, setHeaderLogo] = useState('');
  const [FooterLogo, setFooterLogo] = useState('');
  const [FooterText, setFooterText] = useState('');

  const ROUTE_URL_LIST = [
    { url: '/login', component: <Login /> },
    { url: '/join', component: <Join /> },
    { url: '/projects', component: <Projects /> },
    { url: '/role', component: <Role /> },
    { url: '/design', component: <Design /> },
    { url: '/profiles', component: <Profiles /> },
    { url: '/maintenance', component: <Maintenance /> },
    { url: '/scan', component: <NodeSearch /> }
  ];

  const ROUTE_URL_INDEX = {
    LOGIN: 0,
    JOIN: 1,
    PROJECT: 2,
    ROLE: 3,
    DESIGN: 4,
    PROFILES: 5,
    MAINTENANCE: 6
  };

  const onClick_Logout = () => {
    modal.confirm({
      title: t('modal.logout.title'),
      content: t('modal.logout.content'),
      okText: t('modal.button.ok'),
      cancelText: t('modal.button.cancel'),
      onOk: () => {
        window.sessionStorage.clear();
        setID('');
        setAccessToken('');
        setRefreshToken('');
        setLogoutRemainingTime();

        for (const e of BRAND_URL) {
          if (window.location.pathname.includes(e.url)) {
            navigate((e.url + ROUTE_URL_LIST[ROUTE_URL_INDEX.LOGIN].url), { replace: true });
            break;
          }
        }
      }
    });
  };

  const onFinish_LogoutCountdown = () => {
    Refresh_API();
  };

  const LogoutProcess = () => {
    modal.info({
      title: t('modal.sign_in_expired.title'),
      content: t('modal.sign_in_expired.content'),
      okText: t('modal.button.ok'),
      onOk: () => {
        window.sessionStorage.clear();
        setID('');
        setAccessToken('');
        setRefreshToken('');
        setLogoutRemainingTime();

        for (const e of BRAND_URL) {
          if (window.location.pathname.includes(e.url)) {
            navigate((e.url + ROUTE_URL_LIST[ROUTE_URL_INDEX.LOGIN].url), { replace: true });
            break;
          }
        }
      }
    });
  };

  const Refresh_API = () => {
    mutex.runExclusive(() => {
      ApiRefresh({ id: ID }, AccessToken, RefreshToken)
        .then((res) => {
          console.log(res.status);
          if (res.status === API_RESPONSE_CODE.OK) {
            setAccessToken(res.headers['emblaze-at-v1']);
            setRefreshToken(res.headers['emblaze-rt-v1']);
            window.sessionStorage.setItem('accessToken', res.headers['emblaze-at-v1']);
            window.sessionStorage.setItem('refreshToken', res.headers['emblaze-rt-v1']);

            window.sessionStorage.setItem('loginDatetime', new Date());
            let loginDatetime = new Date(window.sessionStorage.loginDatetime);
            setLogoutRemainingTime(loginDatetime.setHours(loginDatetime.getHours() + 3));
            // setLogoutRemainingTime(loginDatetime.setSeconds(loginDatetime.getSeconds() + 3));
            window.location.reload();
          } else {
            console.log(res.status);
            // LogoutProcess();
          }
        }).catch((err) => {
          console.log(err.response.status);
          // LogoutProcess();
        });
    }).catch((value) => {
      console.log(value);
    });
  };

  useEffect(() => {
    if (window.location.pathname === '/maintenance') {
      navigate(ROUTE_URL_LIST[ROUTE_URL_INDEX.MAINTENANCE].url, { replace: true });
    } else if (window.location.pathname === '/scan') {

    } else if (ID != null) {
      for (const e of BRAND_URL) {
        if (window.location.pathname.includes(e.url)) {
          const real_url = window.location.pathname.replace(e.url, '');
          if ((real_url === '') || (real_url === '/') || (real_url === ROUTE_URL_LIST[ROUTE_URL_INDEX.LOGIN].url) || (real_url === ROUTE_URL_LIST[ROUTE_URL_INDEX.JOIN].url)) {
            navigate((e.url + ROUTE_URL_LIST[ROUTE_URL_INDEX.PROJECT].url), { replace: true });
            break;
          }
        }
      }
    } else {
      for (const e of BRAND_URL) {
        if (window.location.pathname.includes(e.url)) {
          const real_url = window.location.pathname.replace(e.url, '');
          if (real_url === ROUTE_URL_LIST[ROUTE_URL_INDEX.JOIN].url) {
            navigate((e.url + ROUTE_URL_LIST[ROUTE_URL_INDEX.JOIN].url), { replace: true });
            break;
          } else {
            navigate((e.url + ROUTE_URL_LIST[ROUTE_URL_INDEX.LOGIN].url), { replace: true });
            break;
          }
        }
      }
    }

    for (const e of BRAND_URL) {
      if (window.location.pathname.includes(e.url)) {
        setHomeLink(e.url);
        setHeaderLogo(e.header_logo);
        setFooterLogo(e.small_logo);
        if (e.brand === 'emblaze') {
          setFooterText('ⓒ 2022. Neostack Inc. All rights reserved.');
        }
        return;
      }
    }
  }, []);

  useEffect(() => {
    if (window.sessionStorage.loginDatetime != null) {
      let loginDatetime = new Date(window.sessionStorage.loginDatetime);
      setLogoutRemainingTime(loginDatetime.setHours(loginDatetime.getHours() + 3));
      // setLogoutRemainingTime(loginDatetime.setSeconds(loginDatetime.getSeconds() + 3));
    }
  }, [window.sessionStorage.loginDatetime]);

  return (
    <AppContext.Provider value={{ ID, setId: setID, accessToken: AccessToken, setAccessToken, refreshToken: RefreshToken, setRefreshToken, Refresh_API, ROUTE_URL_LIST, ROUTE_URL_INDEX }}>

      <Layout>
        {(window.sessionStorage.signInId != null) ?
          <Header className='layout_header'>
            <Space className='space_between'>
              <Space className='logo'>
                <Image preview={false} src={HeaderLogo} onClick={() => window.location.replace(HomeLink)} />
              </Space>
              <Space size={50}>
                <Countdown style={{ visibility: 'hidden' }} value={LogoutRemainingTime} onFinish={onFinish_LogoutCountdown} />
                <Dropdown dropdownRender={() => {
                  return (
                    <Space direction='vertical' size={0}>
                      <ModalLanguageChange type={0} />
                      <ModalUserInfoUpdate />
                      <ModalUserPasswordUpdate />
                      <Button className='header_menu_button' icon={<LogoutOutlined />} onClick={onClick_Logout}>{t('main_menu.setting.logout')}</Button>
                    </Space>
                  );
                }}>
                  <Button className='button' type='primary' icon={<UserOutlined />}>{((ID != null) ? ID : t('main_menu.no_id'))}</Button>
                </Dropdown>
              </Space>
            </Space>
          </Header> : null}

        <Content className='layout_content'>
          <Routes>
            {BRAND_URL.map((value_upper) => (
              ROUTE_URL_LIST.map((value_lower) => (
                <Route path={value_upper.url + value_lower.url} element={value_lower.component} />
              ))
            ))}
            <Route path='*' element={<Space className='empty_page space_center'>404 Error: Page Not Found</Space>} />
          </Routes>
        </Content>

        <Footer className='layout_footer'>
          <Space className='space_center' direction='vertical' size={0}>
            <Image width={200} preview={false} src={FooterLogo} />
            <Text className='footer_text'>{FooterText}</Text>
          </Space>
        </Footer>
      </Layout>

    </AppContext.Provider>
  );
}