export const TimestampConvert = (timestamp) => {
  let ts = new Date(timestamp);
  return (
    ts.getFullYear()
    + '-' + ((ts.getMonth() >= 9) ? (ts.getMonth() + 1).toString() : ('0' + (ts.getMonth() + 1).toString()))
    + '-' + ((ts.getDate() >= 10) ? ts.getDate() : ('0' + ts.getDate()))
    + ' ' + ((ts.getHours() >= 10) ? ts.getHours() : ('0' + ts.getHours()))
    + ':' + ((ts.getMinutes() >= 10) ? ts.getMinutes() : ('0' + ts.getMinutes()))
    + ':' + ((ts.getSeconds() >= 10) ? ts.getSeconds() : ('0' + ts.getSeconds()))
  );
};

export const TimezoneConvert = (utc) => {
  let return_value = 'GMT+' + utc;
  switch (utc) {
    case 9:
      return ('Asia/Seoul (' + return_value + ')');
    default:
      return ('Abroad (' + return_value + ')');
  }
};