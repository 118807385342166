import { useContext, useState } from 'react';
import { DeleteOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { App, Modal, Button, Space } from 'antd';
import { RoleContext } from '../../pages/Role';
import { API_LIST, API_RESPONSE_CODE, ApiMain, METHOD } from '../../common/Api';
import { AppContext } from '../../App';
import { useTranslation } from 'react-i18next';

export default function ModalRoleDelete(props) {
  const { t } = useTranslation();
  const { modal } = App.useApp();
  const { ID, accessToken, Refresh_API } = useContext(AppContext);
  const { GetRoles } = useContext(RoleContext);

  const [ModalOpen, setModalOpen] = useState(false);
  const [Loading, setLoading] = useState(false);

  const onClick_Modal = () => {
    setModalOpen(true);
  };

  const onCancel_Modal = () => {
    setModalOpen(false);
  };

  const onOk_Modal = () => {
    setLoading(true);

    ApiMain(API_LIST.USER_ROLE_DELETE, METHOD.POST, {
      id: ID,
      target: props.target,
      bldg_idx: props.bldg.bldg_idx
    }, accessToken).then((res) => {
      switch (res.status) {
        case API_RESPONSE_CODE.ACCEPTED:
          modal.success({
            content: t('modal.role.delete.complete'),
            okText: t('modal.button.ok'),
            onOk: () => {
              GetRoles();
              setModalOpen(false);
            }
          });
          break;

        case API_RESPONSE_CODE.NO_CONTENT:
          modal.warning({
            content: t('modal.role.delete.no_content'),
            okText: t('modal.button.ok')
          });
          break;

        default:
          modal.warning({
            content: t('modal.common.warnning.unkown_response') + res.status,
            okText: t('modal.button.ok')
          });
          break;
      }
      setLoading(false);
    }).catch((err) => {
      switch (err.response.status) {
        case API_RESPONSE_CODE.PRECONDITION_FAILED:
          Refresh_API();
          break;
          
        case API_RESPONSE_CODE.UNAUTHORIZED:
          modal.error({
            content: t('modal.role.delete.warnning.unauthorized'),
            okText: t('modal.button.ok')
          });
          break;

        case API_RESPONSE_CODE.FORBIDDEN:
          modal.error({
            content: t('modal.role.delete.warnning.forbidden'),
            okText: t('modal.button.ok')
          });
          break;

        case API_RESPONSE_CODE.NOT_ACCEPTABLE:
          modal.error({
            content: t('modal.common.warnning.not_acceptable'),
            okText: t('modal.button.ok')
          });
          break;

        case API_RESPONSE_CODE.BAD_REQUEST:
          modal.error({
            content: t('modal.common.warnning.bad_request'),
            okText: t('modal.button.ok')
          });
          break;

        case API_RESPONSE_CODE.SERVICE_UNAVAILABLE:
          modal.error({
            content: t('modal.common.warnning.server_error'),
            okText: t('modal.button.ok')
          });
          break;

        default:
          modal.error({
            content: t('modal.common.warnning.unknown_error') + err.response.status,
            okText: t('modal.button.ok')
          });
          break;
      }
      setLoading(false);
      setModalOpen(false);
    });
  };

  return (
    <>
      <Button onClick={onClick_Modal} type='primary' danger={true} icon={<DeleteOutlined />} disabled={props.disable} />

      <Modal closable={false} open={ModalOpen} confirmLoading={Loading} onOk={onOk_Modal} onCancel={onCancel_Modal} okText={t('modal.button.ok')} cancelText={t('modal.button.cancel')}
        title={
          <Space>
            <InfoCircleOutlined className='modal_icon_danger' />
            {t('modal.role.delete.title')}
          </Space>}>
        {t('modal.role.delete.content')}
      </Modal>
    </>
  );
}