import React from 'react';
import * as ReactDOM from 'react-dom/client';
import App from './App';
import i18n from './components/language/i18n';
import { BrowserRouter } from 'react-router-dom';
import { App as AntdApp, ConfigProvider } from 'antd';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <BrowserRouter>
    <ConfigProvider theme={{
      token: {
        colorPrimary: '#F4BD40',
        colorBgBase: '#222222',
        colorTextBase: '#FFFFFF',
        colorBorder: '#777777',
        fontSize: 16
      },
      components: {
        Button: {
          primaryShadow: '#000000',
          dangerShadow: '#000000',
          primaryColor: '#222222'
        },
        Input: {
          activeBg: '#000000',
          colorPrimaryActive: '#000000',
          activeShadow: '#000000',
        },
        Tooltip: {
          colorBgSpotlight: '#444444'
        },
        Select: {
          optionSelectedBg: '#777777'
        },
        Card: {
          colorBorderSecondary: '#444444',
        },
        Radio: {
          buttonSolidCheckedColor: '#222222'
        },
        Menu: {
          darkItemSelectedColor: '#222222'
        },
        Layout: {
          headerHeight: 50
        },
        Statistic: {
          titleFontSize: 20
        },
        Segmented: {
          itemSelectedBg: '#F4BD40',
          itemSelectedColor: '#222222'
        },
        Alert: {
          colorWarningBg: '#000000'
        },
        Table: {
          borderColor: '#444444',
          headerSplitColor: '#444444'
        }
      }
    }}>
      <AntdApp>
        <App />
      </AntdApp>
    </ConfigProvider>
  </BrowserRouter>
);